import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CategoriesImagesGrid from "../components/CategoriesImagesGrid";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

import { categories2psv } from "../lib/product";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    flexGrow: 1,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
});

function CategoriesImages(props) {
  const { classes } = props;

  // State flags.
  const [loading, setLoading] = useState(false);
  const [categoriesImages, setCategoriesImages] = useState([]);

  const notifications = useContext(NotificationContext);

  const { catalogName } = useParams();

  function extractL1Categories(categories) {
    const listOfCats = categories2psv(categories, false);

    return Array(
      ...new Set(listOfCats.map((category) => category.split("|")[0]).sort())
    );
  }

  function mergeImagesStructure(categoriesImages, L1categories) {
    let i = 0,
      j = 0;
    let result = [];

    while (true) {
      if (i >= categoriesImages.length) {
        for (let a = j; a < L1categories.length; a++) {
          result.push({
            category: L1categories[a].trim(),
            image: "../image-na.jpg",
          });
        }
        break;
      }

      // This is not required because even if we have images for these categories,
      // the categories no longer exist, so they are irrelevant.
      //if (j >= L1categories.length) {
      //  for (let a = i; a < categoriesImages.length; a++) {
      //    result.push(categoriesImages[a]);
      //  }
      //  break;
      //}

      const fromImage = categoriesImages[i].category.trim().toLowerCase(),
        fromCategories = L1categories[j].trim().toLowerCase();

      if (fromImage === fromCategories) {
        result.push(categoriesImages[i]);
        i++;
        j++;
      } else if (fromImage < fromCategories) {
        // is this required? it means we have an image that doens't match a
        // category anymore.
        //result.push(categoriesImages[i]);
        i++;
      } else {
        result.push({
          category: L1categories[j].trim(),
          image: "../image-na.jpg",
        });
        j++;
      }
    }

    return result;
  }

  useEffect(() => {
    setLoading(true);

    Promise.all([
      DimSum.categories(catalogName),
      DimSum.categoriesImages(catalogName),
    ])
      .then(([{ catalog, categories }, { categoriesImages }]) => {
        setCategoriesImages(
          mergeImagesStructure(
            categoriesImages,
            extractL1Categories(categories)
          )
        );
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when fetching categories images."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Typography variant="h5">Categories images for {catalogName}:</Typography>
      <br />
      <CategoriesImagesGrid
        categoriesImages={categoriesImages}
        catalogName={catalogName}
        className={classes.root}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(CategoriesImages);
