import { useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Form, Text, Selection, Submit } from "../components/Form";
import { withStyles } from "@material-ui/core/styles";

import CategorySelection from "./Categories/CategorySelection";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  form: {
    padding: theme.spacing(1, 1),
    maxWidth: 1800,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formSelect: {
    margin: theme.spacing(1),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: 80,
  },
});

function BulkSearchBar(props) {
  const { searchParams, services, onSearch, classes } = props;

  const storeSearchOptions = Object.keys(services).map((key) => {
    return [key, `${key} (${services[key].catalog.name})`];
  });
  storeSearchOptions.unshift(["", "Any"]);

  const [store, setStore] = useState(
    searchParams.sellerName || storeSearchOptions[0][0]
  );

  function onSubmit(params) {
    if ("category" in params) {
      if (Array.isArray(params["category"])) {
        params["category"] =
          params["category"][params["category"].length - 1] || "";
      }
    }
    if (onSearch) onSearch(params);
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" component="h3">
        Bulk Product Edit
      </Typography>
      <Form
        initialValues={searchParams}
        allowEmpty
        formProps={{
          noValidate: true,
          autoComplete: "off",
          className: classes.form,
        }}
        gridProps={{
          spacing: 4,
        }}
        onSubmit={onSubmit}
      >
        <Text
          field="name"
          label="Name"
          size={2}
          className={classes.formControl}
        />
        <Selection
          field="sellerName"
          label="Store"
          size={2}
          className={classes.formControl}
          options={storeSearchOptions}
          value={store}
          onChange={(k, v) =>
            setStore(services[v] ? services[v].catalog.name : "")
          }
        />
        <Selection
          field="allowed"
          label="Allowed"
          size={1}
          className={classes.formSelect}
          options={[
            ["", "Any"],
            ["true", "Yes"],
            ["false", "No"],
          ]}
        />
        <Selection
          field="available"
          label="Availability"
          size={1}
          className={classes.formSelect}
          options={[
            ["", "Any"],
            ["true", "Yes"],
            ["false", "No"],
          ]}
        />
        <Selection
          field="image"
          label="Image"
          size={1}
          className={classes.formSelect}
          options={[
            ["", "Any"],
            ["true", "Yes"],
            ["false", "No"],
          ]}
        />
        <Selection
          field="order"
          label="Order by"
          size={1}
          className={classes.formSelect}
          options={[
            ["", "Any"],
            ["price-asc", "Price ASC"],
            ["price-desc", "Price DESC"],
            ["lasteditdate-asc", "Last Edit Date ASC"],
            ["lasteditdate-desc", "Last Edit Date DESC"],
          ]}
        />
        <CategorySelection
          label="Category"
          field="category"
          store={store}
          style={{ marginTop: 10, width: "100%" }}
          size={2}
          isInput={true}
          split
          allowNonMembers
        />
        <Submit
          size={2}
          label="Search"
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        />
        <Grid item xs={2}>
          {services[store] && (
            <img
              src={services[store].image}
              className={classes.logo}
              alt="Logo"
            />
          )}
        </Grid>
      </Form>
    </Paper>
  );
}

export default withStyles(styles)(BulkSearchBar);
