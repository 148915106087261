const ValidationMap = {
  required: {
    func: (value) => {
      return value !== null && value !== "";
    },
    errorMsg: "Value is required",
  },
  notNegative: {
    func: (value) => {
      if (value) {
        return parseFloat(value) >= 0.0;
      }
      return true;
    },
    errorMsg: "Can't be negative",
  },
  divisibleBy24: {
    func: (value) => {
      if (value) {
        return value % 24 === 0;
      }
      return true;
    },
    errorMsg: "Must be an increment of 24 hours."
  }
};

function validate(value, validations) {
  var errors = [];

  if (validations) {
    validations = Array.isArray(validations) ? validations : [validations];

    validations.forEach((validation) => {
      validation = ValidationMap[validation];

      if (validation) {
        if (!validation.func(value)) {
          errors.push(validation.errorMsg);
        }
      }
    });
  }

  return errors.join(" | ");
}

export default validate;
