import { useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  format as formatFunc,
  parseISO
} from 'date-fns';
import PropTypes from "prop-types";

import DateTime from "./DateTime";

function DateTimeRange(props) {
  const {
    disabled,
    format,
    fromField,
    fromLabel,
    fromRequired,
    fromValue,
    fullWidth,
    maxSize,
    minutesStep,
    readOnly,
    spacing,
    toField,
    toLabel,
    toRequired,
    toValue,
    onChange,
    onFormChange,
    onValidationError,
    size,
    ...rest
  } = props;

  const olderThan = 'Date must be older than: ';
  const newerThan = 'Date must be newer than: ';

  const dateFormat = format ? format : 'MMMM d h:mm a z';
  const maxDate = useRef(toValue),
    maxDateMessage = useRef(toValue ? olderThan + formatFunc(parseISO(toValue), dateFormat) : null),
    minDate = useRef(fromValue),
    minDateMessage = useRef(fromValue ? newerThan + formatFunc(parseISO(fromValue), dateFormat) : null);

  function setMaxDate(date) {
    if (date) {
      maxDate.current = date.toISOString();
      maxDateMessage.current = olderThan + formatFunc(date, dateFormat);
    }
    else {
      maxDate.current = undefined;
      maxDateMessage.current = undefined;
    }
  }

  function setMinDate(date) {
    if (date) {
      minDate.current = date.toISOString();
      minDateMessage.current = newerThan + formatFunc(date, dateFormat);
    }
    else {
      minDate.current = undefined;
      minDateMessage.current = undefined;
    }
  }

  const [fromDate, handleFromChange] = useState(fromValue ? parseISO(fromValue) : null);
  const [toDate, handleToChange] = useState(toValue ? parseISO(toValue) : null);

  function onLocalChange(field, dateIso) {
    let errorMessage;
    let date = dateIso ? parseISO(dateIso) : dateIso;
    let otherDate = field === fromField ? toDate : fromDate;
    if (date && otherDate) {
      if (field === fromField) {
        if (date > toDate)
          errorMessage = maxDateMessage.current;
      }
      else
        if (date < fromDate)
          errorMessage = minDateMessage.current;
    }

    if (errorMessage) {
      if (onValidationError) onValidationError(field, errorMessage);
    }
    else {
      if (onFormChange) onFormChange(field, dateIso);
      if (onChange) onChange(field, dateIso);
    }
    if (field === fromField) {
      setMinDate(date);
      handleFromChange(date);
    }
    else {
      setMaxDate(date);
      handleToChange(date);
    }
  }

  return (
    <Grid container item xs={size * 2 < maxSize ? size * 2 : maxSize} spacing={spacing}>
      <DateTime
        disabled={disabled}
        field={fromField}
        format={dateFormat}
        fullWidth={fullWidth}
        label={fromLabel}
        maxDate={maxDate.current}
        maxDateMessage={maxDateMessage.current}
        minutesStep={minutesStep}
        onChange={onLocalChange}
        readOnly={readOnly}
        required={fromRequired}
        size={size}
        value={fromValue}
        {...rest}
      />
      <DateTime
        disabled={disabled}
        field={toField}
        format={dateFormat}
        fullWidth={fullWidth}
        label={toLabel}
        minDate={minDate.current}
        minDateMessage={minDateMessage.current}
        minutesStep={minutesStep}
        onChange={onLocalChange}
        readOnly={readOnly}
        required={toRequired}
        size={size}
        value={toValue}
        {...rest}
      />
    </Grid>
  );
}

DateTimeRange.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  fromField: PropTypes.string,
  fromLabel: PropTypes.string,
  fromRequired: PropTypes.bool,
  fromValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxSize: PropTypes.number,
  minutesStep: PropTypes.number,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  readOnly: PropTypes.bool,
  size: PropTypes.number,
  spacing: PropTypes.number,
  toField: PropTypes.string,
  toLabel: PropTypes.string,
  toRequired: PropTypes.bool,
  toValue: PropTypes.string,
}

export default DateTimeRange;
