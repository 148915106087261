import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import CategoryImageCard from "./CategoryImageCard";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gridGap: "2em 1.1em",
  },
});

function CategoriesImagesGrid(props) {
  const { classes, categoriesImages, catalogName } = props;

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {categoriesImages.map((image, idx) => (
          <CategoryImageCard
            key={idx}
            categoryImage={image}
            catalogName={catalogName}
          />
        ))}
      </Paper>
    </React.Fragment>
  );
}

export default withStyles(styles)(CategoriesImagesGrid);
