import { API } from "aws-amplify";
import {
  getBuiltinCatalogs,
  getBuiltinServices,
  getCategoriesImages,
  getNotifications,
  getProduct,
  searchProducts,
} from "../graphql/queries";
import {
  addProduct,
  bulkUpdate,
  putBuiltInService,
  updateCategories,
  updateShopperCategories,
  updateCategoriesBlacklist,
  updateCategoryImage,
  updateProduct,
  setFeaturedProduct,
} from "../graphql/mutations";

// Overriden from schema because we need more nesting
const getCategories = /* GraphQL */ `
  query GetCategories($sellerName: String!) {
    getCategories(sellerName: $sellerName) {
      catalog {
        name
        editableCategories
      }
      categories {
        name
        subcategories {
          name
          subcategories {
            name
            subcategories {
              name
              subcategories {
                name
                subcategories {
                  name
                  subcategories {
                    name
                    subcategories {
                      name
                      subcategories {
                        name
                        subcategories {
                          name
                          subcategories {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Overriden from schema because we need more nesting
const getShopperCategories = /* GraphQL */ `
  query GetShopperCategories($sellerName: String!) {
    getShopperCategories(sellerName: $sellerName) {
      catalog {
        name
        editableCategories
      }
      categories {
        name
        subcategories {
          name
          subcategories {
            name
            subcategories {
              name
              subcategories {
                name
                subcategories {
                  name
                  subcategories {
                    name
                    subcategories {
                      name
                      subcategories {
                        name
                        subcategories {
                          name
                          subcategories {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Overriden from schema because we need more nesting
const getCategoriesBlacklist = /* GraphQL */ `
  query GetCategoriesBlacklist($catalogName: String!) {
    getCategoriesBlacklist(catalogName: $catalogName) {
      catalog {
        name
        editableCategories
      }
      blacklist {
        name
        subcategories {
          name
          subcategories {
            name
            subcategories {
              name
              subcategories {
                name
                subcategories {
                  name
                  subcategories {
                    name
                    subcategories {
                      name
                      subcategories {
                        name
                        subcategories {
                          name
                          subcategories {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class DimSum {
  /**********************************************************************
   * Return a list of built in Dumpling services.
   **********************************************************************/
  static async builtinServices() {
    const response = await API.graphql({ query: getBuiltinServices });
    let fetchedServices = {};

    // Index of services
    response.data.getBuiltinServices.forEach((service) => {
      fetchedServices[service["name"]] = service;
    });

    return fetchedServices;
  }

  /**********************************************************************
   * Return a list of built in Dumpling product catalogs.
   **********************************************************************/
  static async builtinCatalogs() {
    const response = await API.graphql({ query: getBuiltinCatalogs });

    return response.data.getBuiltinCatalogs;
  }

  /**********************************************************************
   * Return the category structure for the given catalog.
   **********************************************************************/
  static async categories(sellerName) {
    const response = await API.graphql({
      query: getCategories,
      variables: {
        sellerName: sellerName,
      },
    });

    return response.data.getCategories;
  }

  /**********************************************************************
   * Return the category structure for the given catalog.
   **********************************************************************/
    static async shopperCategories(sellerName) {
      const response = await API.graphql({
        query: getShopperCategories,
        variables: {
          sellerName: sellerName,
        },
      });
      return response.data.getShopperCategories;
    }

  /**********************************************************************
   * Return the categories images for the given catalog.
   **********************************************************************/
  static async categoriesImages(catalogName) {
    const response = await API.graphql({
      query: getCategoriesImages,
      variables: {
        catalogName: catalogName,
      },
    });

    return response.data.getCategoriesImages;
  }

  /**********************************************************************
   * Return the category blacklist structure for the given catalog.
   **********************************************************************/
  static async categoriesBlacklist(catalogName) {
    const response = await API.graphql({
      query: getCategoriesBlacklist,
      variables: {
        catalogName,
      },
    });

    return response.data.getCategoriesBlacklist;
  }

  /**********************************************************************
   * DimSum product search.
   **********************************************************************/
  static async productSearch(
    name,
    service,
    top,
    skip,
    searchType,
    filters,
    order
  ) {
    const response = await API.graphql({
      query: searchProducts,
      variables: {
        name,
        sellerName: service,
        top,
        skip,
        filters,
        order,
        searchType,
      },
    });

    return response.data.searchProducts;
  }

  static async productBulkUpdate(ids, changes) {
    const response = await API.graphql({
      query: bulkUpdate,
      variables: {
        ids: ids,
        input: changes,
      },
    });

    return response.data.bulkUpdate;
  }

  static async servicePut(input) {
    const response = await API.graphql({
      query: putBuiltInService,
      variables: {
        input,
      },
    });

    return response.data.putBuiltInService;
  }

  static async categoriesUpdate(catalogName, categories) {
    const response = await API.graphql({
      query: updateCategories,
      variables: {
        input: {
          catalogName,
          categories,
        },
      },
    });

    return response.data.updateCategories;
  }

  static async shopperCategoriesUpdate(catalogName, categories) {
    const response = await API.graphql({
      query: updateShopperCategories,
      variables: {
        input: {
          catalogName,
          categories,
        },
      },
    });

    return response.data.updateShopperCategories;
  }

  static async categoriesBlacklistUpdate(catalogName, blacklist) {
    const response = await API.graphql({
      query: updateCategoriesBlacklist,
      variables: {
        input: {
          catalogName,
          blacklist,
        },
      },
    });

    return response.data.updateCategoriesBlacklist;
  }

  static async updateCategoryImage(input) {
    const response = await API.graphql({
      query: updateCategoryImage,
      variables: { input },
    });

    return response.data.updateCategoryImage;
  }

  static async getProduct(id) {
    const response = await API.graphql({
      query: getProduct,
      variables: { id },
    });

    return response.data.getProduct;
  }

  static async updateProduct(id, input) {
    const response = await API.graphql({
      query: updateProduct,
      variables: { id, input },
    });

    return response.data.updateProduct;
  }

  static async addProduct(input) {
    const response = await API.graphql({
      query: addProduct,
      variables: { input },
    });

    return response.data.addProduct;
  }

  static async setFeaturedProduct(id, featured) {
    const response = await API.graphql({
      query: setFeaturedProduct,
      variables: { id, featured },
    });

    return response.data.setFeaturedProduct;
  }

  /**********************************************************************
   * Return outstanding notifications
   **********************************************************************/
  static async getBanners() {
    const response = await API.graphql({ query: getNotifications });

    return response.data.getNotifications;
  }

}

export default DimSum;
