import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  helpText: {
    marginLeft: theme.spacing(3),
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Progress(props) {
  const { classes, current, total } = props;

  const progress = Math.min(Math.ceil((current / total) * 100), 100);

  return (
    <Paper className={classes.root}>
      <LinearProgressWithLabel
        value={isNaN(progress) ? 0 : progress}
        variant="determinate"
      />
    </Paper>
  );
}

export default withStyles(styles)(Progress);
