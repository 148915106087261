import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Check,
  Form,
  Text,
  Selection,
  Submit,
  Upload,
  Number
} from "../components/Form";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

import config from "../aws-exports";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    maxWidth: 600,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  productImage: {
    height: 100,
    width: "auto",
  },
  imageContainer: {
    textAlign: "center",
    align: "center",
  },
  formControl: {
    margin: theme.spacing(1),
  },
});

const defaultParams = {
  name: "",
  image: "",
  testService: false,
  catalogName: "davabase",
  site: "",
  allowSiteSearch: true,
  allowImageSearch: true,
  allowGenericGrocery: false,
  lockoutHours: 72
};

function ServiceAdd(props) {
  const { classes } = props;

  // State flags.
  const [loading, setLoading] = useState(false);
  const [availableCatalogs, setAvailableCatalogs] = useState([]);

  const history = useHistory();

  const notifications = useContext(NotificationContext);

  useEffect(() => {
    setLoading(true);

    DimSum.builtinServices()
      .then((services) => {
        const catalogs = Object.values(services).map(
          (service) => service.catalog.name
        );
        setAvailableCatalogs(Array(...new Set(catalogs)));
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when fetching available services."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  function catalogOptions() {
    const catalogs = availableCatalogs.map((catalog) => [catalog, catalog]);

    return [["", "Create new catalog"]].concat(catalogs);
  }

  async function onSubmit(form) {
    setLoading(true);

    if (form.imageUpload) {
      try {
        const ext = form.imageUpload.name
          .substr(form.imageUpload.name.lastIndexOf(".") + 1)
          .toLowerCase();
        const key = `dimsum/images/uploads/service_logos/${uuidv4()}.${ext}`;

        await Storage.put(key, form.imageUpload, {
          contentType: form.imageUpload.type,
        });

        form.image = `https://${config.Storage.AWSS3.bucket}.s3.${config.Storage.AWSS3.region}.amazonaws.com/public/${key}`;
      } catch (error) {
        notifications.setErrorMessage(`Error uploading file: ${error}`);
        setLoading(false);
        return;
      } finally {
        delete form.imageUpload;
      }
    }

    // If this property is empty the form sets it as empty string but the graphql needs it to be null
    if (form.hasOwnProperty('lockoutHours')) {
      if (form.lockoutHours === "") {
        form.lockoutHours = null
      }
    }

    DimSum.servicePut({ ...defaultParams, ...form })
      .then((response) => {
        if (!("errors" in response) || response.errors.length === 0) {
          notifications.setSuccessMessage("Service successfully added!");

          setTimeout(1500, () => history.push("/services"));
        } else {
          notifications.setErrorMessage(
            `Errors when saving service: ${response.errors.join(", ")}`
          );
        }
      })
      .catch((response) =>
        notifications.setErrorMessage("Error trying to save service.")
      )
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5">Create new built-in service:</Typography>
        <br />
        <Form
          onSubmit={onSubmit}
          formProps={{
            className: classes.form,
            noValidate: true,
            autoComplete: "off",
          }}
          gridProps={{
            spacing: 3,
          }}
        >
          <Text
            field="name"
            label="Service Name"
            size={10}
            validations="required"
            required
          />
          <Upload
            field="imageUpload"
            label="Upload Service Logo"
            size={10}
            color="default"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          />

          <Check
            field="testService"
            label="Test service"
            size={4}
            style={{ marginTop: 24 }}
          />

          <Selection
            field="catalogName"
            label="Catalog to use:"
            size={8}
            options={catalogOptions()}
            value={"davabase"}
            className={classes.formControl}
          />

          <Text
            field="site"
            label="Root website"
            size={10}
          />
          <Check
            field="allowSiteSearch"
            label="Site search"
            size={4}
            style={{ marginTop: 12 }}
            value={defaultParams.allowSiteSearch}
          />
          <Check
            field="allowImageSearch"
            label="Image search"
            size={4}
            style={{ marginTop: 12 }}
            value={defaultParams.allowImageSearch}
          />
          <Check
            field="allowGenericGrocery"
            label="Generic grocery"
            size={4}
            style={{ marginTop: 12 }}
            value={defaultParams.allowGenericGrocery}
          />
          <Number
            field="lockoutHours"
            label="Lockout Hours"
            size={10}
            value={defaultParams.lockoutHours}
            validations={["notNegative", "divisibleBy24"]}
          />

          <Submit
            variant="contained"
            color="primary"
            className={classes.button}
            size={10}
            label="Save"
          />
        </Form>
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(ServiceAdd);
