import { useContext } from "react";
import { Button, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NotificationContext from "../contexts/NotificationContext";

import CSVToArray from "../lib/csv-to-array";
import { parseCategoryString, parseOptionCustomizationValue } from "../lib/product";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    width: 380,
  },
  sample: {
    marginLeft: theme.spacing(3),
  },
});

const ALLOWED_MIME_TYPES = ["text/csv", "application/vnd.ms-excel"];

function ProductUpload(props) {
  const { classes, onLoad } = props;

  const notifications = useContext(NotificationContext);

  // name,description,sellerName,price,categories,shopperCategories,imageLink,tags,link,available,allowed
  function rowsToProducts(rows) {
    return rows.map((row) => {
      const available = (row[8] || "").toLowerCase();
      const allowed = (row[9] || "").toLowerCase();
      return {
        sellerId: row[0],
        name: row[1].replace(/\n/, " "),
        description: row[2],
        price: row[3].replace(/\$/g, ""),
        categories: parseCategoryString(row[4]),
        shopperCategories: parseCategoryString(row[5]),
        imageLink: row[6],
        link: row[7],
        available: available === "true" || available === "yes",
        allowed: allowed === "true" || allowed === "yes",
        options: parseOptionCustomizationValue(row[10]),
        customizations: parseOptionCustomizationValue(row[11])
      };
    });
  }

  function handleFile(event) {
    const target = event.target;
    const file = target.files[0];

    if (!ALLOWED_MIME_TYPES.includes(file.type.toLowerCase())) {
      notifications.setErrorMessage("File must be a valid CSV file.");
      console.log(`Reported file type is ${file.type}.`);

      target.value = "";

      return;
    }

    file
      .text()
      .then((data) => {
        try {
          let csv = CSVToArray(data);

          // headers included, remove.
          if (csv[0][0] === "sellerId") {
            csv = csv.slice(1, csv.length);
          }

          // last row is empty, remove.
          if (csv[csv.length - 1].length === 1) {
            csv = csv.slice(0, csv.length - 1);
          }

          onLoad(rowsToProducts(csv));
        } catch {
          notifications.setErrorMessage(
            "There was a problem handling your CSV file. Please check the format."
          );
        }
      })
      .finally(() => (target.value = ""));
  }

  return (
    <Paper className={classes.root}>
      <Button variant="contained" component="label">
        Upload products CSV file
        <input type="file" hidden onChange={handleFile} />
      </Button>
      <a href="/dimsum-bulk-upload.csv" className={classes.sample}>
        Sample csv file
      </a>
    </Paper>
  );
}

export default withStyles(styles)(ProductUpload);
