import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import ProductCard from "./ProductCard";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(345px, 1fr))",
    gridGap: "2em 1.1em",
  },
});

function ProductGrid(props) {
  const { classes, products } = props;

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {products.map((product, idx) => (
          <ProductCard key={idx} product={product} />
        ))}
      </Paper>
    </React.Fragment>
  );
}

export default withStyles(styles)(ProductGrid);
