import { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";

function Upload(props) {
  const { field, label, size, onChange, onFormChange, ...rest } = props;

  const [filename, setFilename] = useState(null);

  function onLocalChange(event) {
    const file = event.target.files[0];

    setFilename(file.name);

    if (onFormChange) onFormChange(field, file);
    if (onChange) onChange(field, file);
  }

  return (
    <Grid item xs={size}>
      <Button component="label" {...rest} onChange={onLocalChange}>
        {!!filename ? filename : label}
        <input type="file" hidden />
      </Button>
    </Grid>
  );
}

Upload.propTypes = {
  field: PropTypes.string,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  size: PropTypes.number,
  label: PropTypes.string,
};

export default Upload;
