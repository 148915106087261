import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    maxWidth: 250,
    width: 250,
  },
  media: {
    height: 140,
  },
  cardHeaderTitle: {
    height: 32,
    maxHeight: 32,
  },
  imageContainer: {
    width: "100%",
    height: 140,
  },
  cardImage: {
    width: 200,
    height: 140,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    objectFit: "contain",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
});

function CategoryImageCard(props) {
  const { categoryImage, catalogName, classes } = props;

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ title: classes.cardHeaderTitle }}
        className={classes.cardHeader}
        title={categoryImage.category}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        <div className={classes.imageContainer}>
          <img
            className={classes.cardImage}
            alt={categoryImage.category}
            src={categoryImage.image || "../image-na.jpg"}
          />
        </div>
      </CardContent>
      <CardActions>
        <Link
          href={`/categories_images/${catalogName}/${categoryImage.category}`}
        >
          <strong>Upload image</strong>
        </Link>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(CategoryImageCard);
