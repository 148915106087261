import { Fragment, useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ServiceGrid from "../components/ServiceGrid";
import NotificationContext from "../contexts/NotificationContext";
import DimSum from "../api/DimSum";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  formControl: {
    margin: theme.spacing(2),
    width: "35ch",
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    verticalAlign: "bottom",
    marginBottom: 15,
  },
  paginationControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function Services(props) {
  const { classes } = props;

  const [loading, setLoading] = useState(false);
  const [availableServices, setAvailableServices] = useState({});

  const notifications = useContext(NotificationContext);

  useEffect(() => {
    setLoading(true);

    DimSum.builtinServices()
      .then((services) => {
        setAvailableServices(services);
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when fetching available services."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Fragment>
        <br />
        <ServiceGrid services={availableServices} />
      </Fragment>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(Services);
