import { useState } from "react";
import { Grid } from "@material-ui/core";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO } from 'date-fns';
import PropTypes from "prop-types";

function DateTime(props) {
  const {
    field,
    format,
    label,
    maxDate,
    maxDateMessage,
    minDate,
    minDateMessage,
    required,
    onChange,
    onFormChange,
    onValidationError,
    size,
    value,
    ...rest
  } = props;
  const [selectedDate, handleDateChange] = useState(value ? parseISO(value) : null);

  function onLocalChange(date) {
    const dateIso = date ? date.toISOString() : null;
    handleDateChange(date);
    if (onFormChange) onFormChange(field, dateIso);
    if (onChange) onChange(field, dateIso);
  }

  return (
    <Grid item xs={size}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          clearable={!required}
          format={format}
          label={label}
          maxDate={maxDate}
          maxDateMessage={maxDateMessage}
          minDate={minDate}
          minDateMessage={minDateMessage}
          onChange={onLocalChange}
          strictCompareDates={true}
          value={selectedDate}
          variant="dialog"
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}

DateTime.propTypes = {
  field: PropTypes.string,
  format: PropTypes.string,
  label: PropTypes.string,
  maxDate: PropTypes.string,
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.string,
  minDateMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  size: PropTypes.number,
  value: PropTypes.string,
}

export default DateTime;
