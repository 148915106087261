import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Typography,
  Link,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

const styles = (theme) => ({
  root: {
    maxWidth: 345,
    width: 345,
  },
  media: {
    height: 140,
  },
  description: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  cardHeaderTitle: {
    height: 64,
    maxHeight: 64,
  },
  cardImage: {
    //    width: 345,
    maxHeight: 150,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  cardActionsSpacing: {
    "& > :last-child": {
      marginRight: 5,
    },
  },
});

function formatProductName(name) {
  if (name.length > 46) {
    return name.substring(0, 42) + " ...";
  } else {
    return name;
  }
}

function ProductCard(props) {
  const { classes } = props;
  const notifications = React.useContext(NotificationContext);
  const [ product, setProduct ] = React.useState(props.product);
  const [isLoading, setIsLoading] = React.useState(false);

  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleFeaturedStateChange = async (evt) => {
    const featured = evt.target.checked;
    setIsLoading(true);

    DimSum.setFeaturedProduct(product.id, featured)
      .then((prd) => {
        notifications.setSuccessMessage(`${prd.featured ? "Added" : "Removed"} "${prd.name}" ${prd.featured ? "to" : "from"} featured products`);
        setProduct((product) => ({...prd}));
      })
      .catch((response) => {
        console.log(response.errors);
        notifications.setErrorMessage(`Error featuring "${product.name}"`);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ title: classes.cardHeaderTitle }}
        className={classes.cardHeader}
        title={formatProductName(product.name)}
        subheader={product.sellerName}
      />
      <CardContent>
        <img
          className={classes.cardImage}
          alt={product.name}
          src={product.image || product.imageLink || "../image-na.jpg"}
        />
        <Typography
          variant="h6"
          color="textPrimary"
          component="p"
          className={classes.description}
        >
          {formatter.format(product.price / 100.0)}{" "}
          {product.allowed !== false ? "" : " (banned)"}{" "}
          {product.available ? "" : " (unavailable)"}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.description}
        >
          {product.description || "No description available."}
        </Typography>
        <div className={classes.tags}>
          {product.tags.map((tag) => (
            <Chip label={tag} />
          ))}
        </div>
      </CardContent>
      <CardActions
        classes={{ spacing: classes.cardActionsSpacing }}
      >
        <Link href={`/product/${product.id}`} target="_blank">
          <strong>Edit product</strong>
        </Link>
        <div style={{marginLeft: "auto", marginRight: "auto"}}/>
        {isLoading &&
          <CircularProgress
            size={20}
            classes={{ root: classes.featureLoading }}
          />
        }
        <FormGroup>
          <FormControlLabel
            control={<Switch
              size="small"
              color='primary'
              checked={product.featured === true}
              onChange={handleFeaturedStateChange}
            />}
            label="Featured"
            labelPlacement="start"
          />
        </FormGroup>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(ProductCard);
