import { Button, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    width: 420,
  },
  helpText: {
    marginLeft: theme.spacing(3),
  },
});

function ProductUploadControl(props) {
  const { classes, onClick } = props;

  return (
    <Paper className={classes.root}>
      <Button variant="contained" color="primary" onClick={onClick}>
        Upload Valid Products
      </Button>
      <Typography variant="caption" className={classes.helpText}>
        Will ignore products with errors.
      </Typography>
    </Paper>
  );
}

export default withStyles(styles)(ProductUploadControl);
