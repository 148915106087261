import React from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import AddBoxIcon from "@material-ui/icons/AddBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExposurePlus2Icon from "@material-ui/icons/ExposurePlus2";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

import BannersContainer from "./components/Banners";
import BannerContext from "./contexts/BannerContext";

import Notification from "./components/Notification";
import NotificationContext from "./contexts/NotificationContext";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  navigation: {
    marginBottom: theme.spacing(1),
    width: 400,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "25%",
    maxHeight: theme.mixins.toolbar.minHeight,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
});

function Layout(props) {
  const { children, classes, history } = props;

  async function handleLogout(event) {
    await Auth.signOut();
    history.push("/");
  }

  function title() {
    const config =
      process.env.REACT_APP_STAGE === "prod"
        ? " (Production Branch)"
        : " (Dev Branch)";
    return `DimSum ${config}`;
  }

  const [banners, setBanners] = React.useState([]);

  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title()}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <img src="/favicon.png" className={classes.logo} alt="Logo" />
        </div>
        <Divider />

        <List>
          <ListItem key="product" className={classes.listItem}>
            <ListItemText
              primaryTypographyProps={{ variant: "h6" }}
              primary={"Product"}
            />
          </ListItem>
          <ListItem
            button
            key={"product-search"}
            className={classes.listItem}
            onClick={() => (window.location = "/")}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Search"} />
          </ListItem>
          <ListItem
            button
            key={"product-add"}
            className={classes.listItem}
            onClick={() => history.push("/product/new")}
          >
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary={"Add"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key="bulk" className={classes.listItem}>
            <ListItemText
              className={classes.listItem}
              primaryTypographyProps={{ variant: "h6" }}
              primary={"Bulk Operations"}
            />
          </ListItem>
          <ListItem
            button
            key={"bulk-add"}
            className={classes.listItem}
            onClick={() => history.push("/bulk_add")}
          >
            <ListItemIcon>
              <ExposurePlus2Icon />
            </ListItemIcon>
            <ListItemText primary={"Add"} />
          </ListItem>
          <ListItem
            button
            key={"bulk-edit"}
            className={classes.listItem}
            onClick={() => history.push("/bulk_edit")}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary={"Edit"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key="services" className={classes.listItem}>
            <ListItemText
              primaryTypographyProps={{ variant: "h6" }}
              primary={"Built-in Services"}
            />
          </ListItem>
          <ListItem
            button
            key={"service-list"}
            className={classes.listItem}
            onClick={() => history.push("/services")}
          >
            <ListItemIcon>
              <ViewModuleIcon />
            </ListItemIcon>
            <ListItemText primary={"List"} />
          </ListItem>
          <ListItem
            button
            key={"service-add"}
            className={classes.listItem}
            onClick={() => history.push("/service/new")}
          >
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary={"Add"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <BannerContext.Provider
          value={{ setBanners }}
        >
          <BannersContainer
            banners={banners}
            onClose={() => setBanners([])}
          />
          <NotificationContext.Provider
            value={{ setErrorMessage, setSuccessMessage }}
          >
            {children}
          </NotificationContext.Provider>
        </BannerContext.Provider>
        <Notification
          type="error"
          open={!!errorMessage}
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <Notification
          type="success"
          open={!!successMessage}
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
      </main>
    </div>
  );
}

export default withRouter(withStyles(styles)(Layout));
