import { Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";

function Submit(props) {
  const { label, size, ...rest } = props;

  return (
    <Grid item xs={size}>
      <Button type="submit" {...rest}>
        {label}
      </Button>
    </Grid>
  );
}

Submit.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
};

export default Submit;
