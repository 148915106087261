import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  AutocompleteText,
  Form,
  Number,
  Text,
  Selection,
  Submit,
} from "../components/Form";
import CategorySelection from "../components/Categories/CategorySelection";
import ShopperCategorySelection from "../components/Categories/ShopperCategorySelection"

import { centsToDollars, dollarsToCents } from "../lib/product";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    maxWidth: 900,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  },
  button: {
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  helpText: {
    marginTop: theme.spacing(3.5),
    verticalAlign: "bottom",
  },
});

const boostingTags = ["boost_1", "boost_2", "boost_3"];

function BulkEditForm(props) {
  const { classes, onSubmit, store } = props;

  return (
    <Paper className={classes.root}>
      <Form
        onSubmit={onSubmit}
        formProps={{
          className: classes.form,
          noValidate: true,
          autoComplete: "off",
        }}
        gridProps={{
          spacing: 3,
        }}
      >
        <Selection
          field="allowed"
          label="Allowed"
          size={5}
          className={classes.formControl}
          options={[
            ["", "No Change"],
            ["true", "Yes"],
            ["false", "No"],
          ]}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Set to "No" if selected products violate company rules
          </Typography>
        </Grid>

        <Selection
          field="available"
          label="Available"
          size={5}
          className={classes.formControl}
          options={[
            ["", "No Change"],
            ["true", "Yes"],
            ["false", "No"],
          ]}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Will set the availability of all selected products.
          </Typography>
        </Grid>

        <CategorySelection
          label="Categories"
          field="categories"
          store={store}
          size={5}
          isInput={true}
          disabled={!store}
          multiple={true}
          value={[]} // when multiple is true, value can't be null
          //allowNonMembers  // not sure what this was set this way, you shouldn't be alble to select a category that doesn't exitst
          split
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Will set the category of all selected products.
          </Typography>
        </Grid>

        <ShopperCategorySelection
          label="Shopper Categories"
          field="shopperCategories"
          store={store}
          size={5}
          isInput={true}
          disabled={!store}
          multiple={true}
          value={[]} // when multiple is true, value can't be null
          //allowNonMembers  // not sure what this was set this way, you shouldn't be alble to select a category that doesn't exitst
          split
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Will set the shopper category of all selected products.
          </Typography>
        </Grid>

        <AutocompleteText
          field="tags"
          label="Change tags (will add, not reset)"
          size={5}
          allowNonMembers
          multiple
          options={boostingTags}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Will add the selected tags to the products (won't remove any.)
          </Typography>
        </Grid>

        <Text
          field="imageLink"
          label="Image Link"
          size={5}
          className={classes.formControl}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Sets the image for all the selected products.
          </Typography>
        </Grid>

        <Number
          field="price"
          label="Price"
          size={5}
          validations="notNegative"
          prefix="$"
          formatter={centsToDollars}
          filter={dollarsToCents}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Sets the price for all the selected products.
          </Typography>
        </Grid>

        <Number
          field="pricePercentChange"
          label="% Price Change"
          size={5}
          suffix="%"
          className={classes.formControl}
        />
        <Grid item size={1} />
        <Grid item size={6} className={classes.helpText}>
          <Typography variant="subtitle1">
            Adjust the existing price by provided percent.
          </Typography>
        </Grid>

        <Submit
          variant="contained"
          color="primary"
          className={classes.button}
          size={3}
          label="Save"
        />
      </Form>
    </Paper>
  );
}

export default withStyles(styles)(BulkEditForm);
