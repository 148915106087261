import { Fragment, useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";

import { bulkAdd } from "../graphql/mutations";

import CatalogSelector from "../components/CatalogSelector";
import ProductTable from "../components/ProductTable";
import ProductUpload from "../components/ProductUpload";
import ProductUploadControl from "../components/ProductUploadControl";
import ProductValidator from "../lib/product-validator";
import Progress from "../components/Progress";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

const BATCH_SIZE = 500;

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  container: {
    marginBottom: theme.spacing(2),
  },
});

function BulkAdd(props) {
  const { classes } = props;

  const notifications = useContext(NotificationContext);

  // Products loaded from the CSV.
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Products SUCCESSFULLY saved
  const [savedProducts, setSavedProducts] = useState([]);
  // Products NOT saved
  const [erroredProducts, setErroredProducts] = useState([]);

  // Upload related state.
  const [uploadTotal, setUploadTotal] = useState(0);
  const [uploadCurrent, setUploadCurrent] = useState(0);

  const [availableCatalogs, setAvailableCatalogs] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState("");

  useEffect(() => {
    setLoading(true);

    DimSum.builtinCatalogs()
      .then((catalogs) => {
        const allCatalogs = catalogs.map((catalog) => catalog.name).sort();

        setAvailableCatalogs(allCatalogs);
        setSelectedCatalog(allCatalogs[0]);
      })
      .catch((response) => {
        setAvailableCatalogs([]);
        setSelectedCatalog("");

        notifications.setErrorMessage(
          "Failed to load catalogs list, please refresh."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  function onCSVUpload(rows) {
    if (rows.length === 0) {
      return;
    }

    setLoading(true);

    // Wait for categories to be preloaded and then generate the table rows.
    ProductValidator.fetchCategories(selectedCatalog)
      .then(() => {
        setProducts(
          rows.map((p) => {
            return { ...p, sellerName: selectedCatalog };
          })
        );
      })
      .finally(() => setLoading(false));
  }

  async function onUpload() {
    if (products.length === 0) {
      notifications.setErrorMessage("Need to upload a CSV file first.");
      return;
    }

    const validProducts = products.filter(
      (p) => ProductValidator.validate(p).length === 0
    );

    setUploadCurrent(0);
    setUploadTotal(validProducts.length);

    let batch = [],
      i,
      j,
      current = 0,
      failed = [],
      saved = [];

    for (i = 0, j = validProducts.length; i < j; i += BATCH_SIZE) {
      batch = validProducts.slice(i, i + BATCH_SIZE);

      try {
        const response = await API.graphql({
          query: bulkAdd,
          variables: {
            input: batch,
          },
        });

        for (const idx in response.data.bulkAdd) {
          const productStatus = response.data.bulkAdd[idx];
          if (productStatus.status !== 'Success')
            failed.push(productStatus.product);
          else
            saved.push(productStatus.product);
        }

        current += batch.length;

        setUploadCurrent(current);
      } catch {
        failed = failed.concat(batch);
        notifications.setErrorMessage(
          "There was an error while trying to do bulk save!"
        );
      }
    }

    if (failed.length > 0) {
      notifications.setSuccessMessage(
        `Uploaded some Products successfully. The following failed`
      );
    } else {
      notifications.setSuccessMessage("Uploaded Products successfully.");
    }

    setProducts([]);
    setUploadTotal(0);
    setUploadCurrent(0);

    setErroredProducts(failed);
    setSavedProducts(saved);
  }

  return (
    <Fragment>
      <div className={classes.container}>
        {uploadTotal === 0 ? (
          <Fragment>
            <CatalogSelector
              available={availableCatalogs}
              onChange={(catalog) => setSelectedCatalog(catalog)}
              disabled={products.length !== 0}
            />
            <br />
            <Typography variant="h5">
              Products to Upload{products.length < 1 ? '' : ' (' + (products.length) + ')'}
            </Typography>
            <ProductTable
              products={products}
              loading={loading}
              validator={ProductValidator}
            />
          </Fragment>
        ) : (
          <Progress total={uploadTotal} current={uploadCurrent} />
        )}
      </div>
      <div className={classes.container}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 8 }}>
            <ProductUpload onLoad={onCSVUpload} />
          </div>

          {products.length > 0 && (
            <div style={{ marginRight: 8 }}>
              <ProductUploadControl onClick={onUpload} />
            </div>
          )}
        </div>
      </div>
      {erroredProducts.length > 0 ? (
        <div className={classes.container}>
          <Typography variant="h5">
            Errored Products ({erroredProducts.length})
          </Typography>
          <Fragment>
            <ProductTable
              products={erroredProducts}
            />
          </Fragment>
        </div>
      ) : ''}
      {savedProducts.length > 0 ? (
        <div className={classes.container}>
          <Typography variant="h5">
            Saved Products ({savedProducts.length})
          </Typography>
          <Fragment>
            <ProductTable
              products={savedProducts}
            />
          </Fragment>
        </div>
      ) : ''}
    </Fragment>
  );
}

export default withStyles(styles)(BulkAdd);
