import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    maxWidth: 345,
    width: 345,
  },
  media: {
    height: 140,
  },
  cardHeaderTitle: {
    height: 32,
    maxHeight: 32,
  },
  imageContainer: {
    width: "100%",
    height: 140,
  },
  cardImage: {
    width: 250,
    height: 140,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    objectFit: "contain",
  },
  cardActions: {
    width: "85%",
    margin: "auto",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
});

function formatName(name) {
  if (name.length > 26) {
    return name.substring(0, 22) + " ...";
  } else {
    return name;
  }
}

function ServiceCard(props) {
  const { service, classes } = props;

  const serviceName = formatName(
    `${service.testService ? "(test) " : ""}${service.name}`
  );

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ title: classes.cardHeaderTitle }}
        className={classes.cardHeader}
        title={serviceName}
        subheader={service.catalog.name}
      />
      <CardContent>
        <div className={classes.imageContainer}>
          <img
            className={classes.cardImage}
            alt={service.name}
            src={service.image || "../image-na.jpg"}
          />
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link href={`/service/${service.name}`} target="_blank">
          <strong>Edit</strong>
        </Link>{" "}
        <div>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Link href={`/categories/${service.catalog.name}`} target="_blank">
            <strong>Categories</strong>
          </Link>
        </div>
        <div>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Link
            href={`/categories_images/${service.catalog.name}`}
            target="_blank"
          >
            <strong>Categories images</strong>
          </Link>
        </div>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(ServiceCard);
