import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Form, Submit, Upload } from "../components/Form";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

import config from "../aws-exports";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    maxWidth: 600,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  imageContainer: {
    textAlign: "center",
    align: "center",
  },
  categoryImage: {
    width: "auto",
    height: 256,
  },
  formControl: {
    margin: theme.spacing(1),
  },
});

function CategoryImageUpload(props) {
  const { classes } = props;

  const { catalogName, categoryName } = useParams();

  // State flags.
  const [loading, setLoading] = useState(false);
  const [categoryImage, setCategoryImage] = useState({
    category: categoryName,
    image: "../image-na.jpg",
  });

  const history = useHistory();

  const notifications = useContext(NotificationContext);

  useEffect(() => {
    setLoading(true);

    DimSum.categoriesImages(catalogName)
      .then(({ catalog, categoriesImages }) => {
        categoriesImages.forEach((image) => {
          if (
            image.category.trim().toLowerCase() ===
            categoryName.trim().toLowerCase()
          ) {
            setCategoryImage(image);
          }
        });
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when fetching categories images."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form) {
    setLoading(true);

    if (form.imageUpload) {
      try {
        const ext = form.imageUpload.name
          .substr(form.imageUpload.name.lastIndexOf(".") + 1)
          .toLowerCase();
        const key = `dimsum/images/uploads/categories_images/${uuidv4()}.${ext}`;

        await Storage.put(key, form.imageUpload, {
          contentType: form.imageUpload.type,
        });

        form.image = `https://${config.Storage.AWSS3.bucket}.s3.${config.Storage.AWSS3.region}.amazonaws.com/public/${key}`;
      } catch (error) {
        notifications.setErrorMessage(`Error uploading file: ${error}`);
        setLoading(false);
        return;
      } finally {
        delete form.imageUpload;
      }
    }

    DimSum.updateCategoryImage({
      image: form.image,
      catalogName,
      categoryName,
    })
      .then((_) => {
        notifications.setSuccessMessage("Image uploaded successfully.");

        setCategoryImage({
          category: categoryName,
          image: form.image,
        });

        setTimeout(
          () => history.push(`/categories_images/${catalogName}`),
          3000
        );
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem uploading your image."
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5">
          Upload image for category {categoryName} in {catalogName}:
        </Typography>
        <br />
        <Form
          onSubmit={onSubmit}
          formProps={{
            className: classes.form,
            noValidate: true,
            autoComplete: "off",
          }}
          gridProps={{
            spacing: 3,
          }}
        >
          <Grid className={classes.imageContainer} item xs={10}>
            <img
              className={classes.categoryImage}
              alt={categoryName}
              src={categoryImage.image}
            />
          </Grid>

          <Upload
            field="imageUpload"
            label="Upload image"
            size={10}
            color="default"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          />

          <Submit
            variant="contained"
            color="primary"
            className={classes.button}
            size={10}
            label="Save"
          />
        </Form>
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(CategoryImageUpload);
