import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

function Notification(props) {
  const { duration, open, onClose, message, type } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      styles={{ width: "100%" }}
      open={open}
      autoHideDuration={duration || 5000}
      onClose={onClose}
    >
      <MuiAlert elevation={6} variant="filled" severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default withStyles(styles)(Notification);
