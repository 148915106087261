import { Fragment } from "react";
import { Paper, Typography, ButtonGroup, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import SelectAllIcon from "@material-ui/icons/SelectAll";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  navigation: {
    marginBottom: theme.spacing(1),
    width: 200,
  },
});

function EditBar(props) {
  const {
    classes,
    selected,
    onSelectVisible,
    onUnselectVisible,
    onUnselectAll,
  } = props;

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5" color="textPrimary" component="p">
          {Object.keys(selected).length} products selected.
        </Typography>
        <hr />
        <ButtonGroup variant="contained">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SelectAllIcon />}
            onClick={onSelectVisible}
          >
            Select all in page
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<ClearIcon />}
            onClick={onUnselectVisible}
          >
            Unselect all in page
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<CancelIcon />}
            onClick={onUnselectAll}
          >
            Unselect Everything
          </Button>
        </ButtonGroup>
      </Paper>
    </Fragment>
  );
}

export default withStyles(styles)(EditBar);
