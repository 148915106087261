import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import AutocompleteText from "./AutocompleteText";
import Check from "./Check";
import DateTime from "./DateTime";
import DateTimeRange from "./DateTimeRange";
import Number from "./Number";
import Selection from "./Selection";
import Submit from "./Submit";
import Upload from "./Upload";
import Text from "./Text";
import Options from "./Options";
import Customizations from "./Customizations";
import HTMLEditor from "./HTMLEditor";
import JSONEditor from "./JSONEditor";

function Form(props) {
  const { allowEmpty, formProps, gridProps, onSubmit } = props;

  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [submitable, setSubmitable] = React.useState(!!allowEmpty);

  const initial = props.initialValues || {};

  let requiredFields = [];

  const children = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      // Is this one of ours?
      if (
        child.type === AutocompleteText ||
        child.type === Text ||
        child.type === Number ||
        child.type === Selection ||
        child.type === Check ||
        child.type === Upload ||
        child.type === DateTime ||
        child.type === Options ||
        child.type === Customizations ||
        child.type === HTMLEditor ||
        child.type === JSONEditor
      ) {
        let extraProps = { onFormChange: onFormChange };

        if (child.props.value || initial[child.props.field]) {
          extraProps.value = child.props.value || initial[child.props.field];
        }

        // Only text fields validate input.
        if (
          child.type === Text ||
          child.type === Number ||
          child.type === AutocompleteText
        ) {
          extraProps.onValidationError = onValidationError;
        }

        if (!!child.props.required) {
          requiredFields.push(child.props.field);
        }

        return React.cloneElement(child, extraProps);
      } else if (child.type === DateTimeRange) {
        const extraProps = {
          onFormChange: onFormChange,
          onValidationError: onValidationError,
        };

        if (!!child.props.fromRequired) {
          requiredFields.push(child.props.fromField);
        }
        if (!!child.props.toRequired) {
          requiredFields.push(child.props.toField);
        }

        if (child.props.fromValue || initial[child.props.fromField]) {
          extraProps.fromValue =
            child.props.fromValue || initial[child.props.fromField];
        }
        if (child.props.toValue || initial[child.props.toField]) {
          extraProps.toValue =
            child.props.toValue || initial[child.props.toField];
        }

        return React.cloneElement(child, extraProps);
      } else if (!!child.props.isInput) {
        const extraProps = {
          onFormChange: onFormChange,
          onValidationError: onValidationError,
        };

        if (!!child.props.required) {
          requiredFields.push(child.props.field);
        }

        if (child.props.value || initial[child.props.field]) {
          extraProps.value = child.props.value || initial[child.props.field];
        }

        return React.cloneElement(child, extraProps);
      } else if (child.type === Submit) {
        return React.cloneElement(child, {
          disabled: !submitable,
        });
      }
    }

    return child;
  });

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function allRequired() {
    for (let i = 0; i < requiredFields.length; i++) {
      if (!(requiredFields[i] in form) && !(requiredFields[i] in initial)) {
        return false;
      }
    }

    return true;
  }

  function onLocalSubmit(e) {
    e.preventDefault();
    onSubmit(form);
  }

  function onFormChange(key, value) {
    let newErrors = errors;
    let newForm = form;

    // Field has no error now.
    delete newErrors[key];

    // Did the value actually change?
    if (initial[key] !== value) {
      newForm[key] = value;
    } else {
      delete newForm[key];
    }

    setErrors(newErrors);
    setForm(newForm);
    setSubmitable(
      (!isEmpty(newForm) || allowEmpty) && isEmpty(errors) && allRequired()
    );
  }

  function onValidationError(key, errorStr) {
    console.error(errors)
    setSubmitable(false);
    setErrors({ ...errors, [key]: errorStr });
  }

  return (
    <form {...formProps} onSubmit={onLocalSubmit}>
      <Grid container {...gridProps}>
        {children}
      </Grid>
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {},
};

export default Form;
