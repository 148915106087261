import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Form, Text, Submit } from "../components/Form";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

import { psv2categories, categories2psv } from "../lib/product";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    flexGrow: 1,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  editor: {
    fontFamily: "monospace",
  },
  example: {
    color: "#444444",
    fontFamily: "monospace",
    fontSize: "1.2em",
    backgroundColor: "#cccccc",
    padding: 8,
    width: "300px",
  },
});

const exampleValue = `Produce
Produce|Fruits
Produce|Fruits|Fresh
Produce|Fruits|Frozen
Produce|Vegetables
Produce|Vegetables|Fresh
Produce|Vegetables|Frozen
`;

function CategoriesAdd(props) {
  const { classes } = props;

  // State flags.
  const [loading, setLoading] = useState(false);
  const [readOnlyCategories, setReadOnlyCategories] = useState(true);
  const [categoriesBuffer, setCategoriesBuffer] = useState("");
  const [shopperCategoriesBuffer, setShopperCategoriesBuffer] = useState("");
  const [blacklistBuffer, setBlacklistBuffer] = useState("");

  const notifications = useContext(NotificationContext);

  const { catalogName } = useParams();

  useEffect(() => {
    setLoading(true);

    Promise.all([
      DimSum.categories(catalogName),
      DimSum.shopperCategories(catalogName),
      DimSum.categoriesBlacklist(catalogName),
    ])
      .then(([{ catalog, categories }, { catalog: shopperCatalog, categories: shopperCategories }, { blacklist }]) => {
        setCategoriesBuffer(categories2psv(categories, true).join("\n"));
        setShopperCategoriesBuffer(categories2psv(shopperCategories, true).join("\n"));
        setBlacklistBuffer(
          blacklist ? categories2psv(blacklist, false).join("\n") : ""
        );
        setReadOnlyCategories(!catalog.editableCategories);
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when fetching available services."
        );
        setReadOnlyCategories(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  function onSubmit(form) {
    if ("categories" in form) {
      if (readOnlyCategories) {
        notifications.setErrorMessage(
          "This catalog categories cannot be edited."
        );
      } else {
        DimSum.categoriesUpdate(
          catalogName,
          psv2categories(form.categories.split("\n"))
        )
          .then((response) => {
            notifications.setSuccessMessage("Categories updated succesfully.");
          })
          .catch((response) => {
            notifications.setErrorMessage(
              "There was a problem saving the categories."
            );
          });
      }
    }

    if ("shopperCategories" in form) {
      if (readOnlyCategories) {
        notifications.setErrorMessage(
          "This catalog shopper categories cannot be edited."
        );
      } else {
        DimSum.shopperCategoriesUpdate(
          catalogName,
          psv2categories(form.shopperCategories.split("\n"))
        )
          .then((response) => {
            notifications.setSuccessMessage("Shopper categories updated succesfully.");
          })
          .catch((response) => {
            notifications.setErrorMessage(
              "There was a problem saving the shopper categories."
            );
          });
      }
    }

    if ("blacklist" in form) {
      DimSum.categoriesBlacklistUpdate(
        catalogName,
        psv2categories(form.blacklist.split("\n"))
      )
        .then((response) => {
          notifications.setSuccessMessage("Blacklist updated successfully.");
        })
        .catch((response) => {
          notifications.setErrorMessage(
            "There was a problem saving the blacklist."
          );
        });
    }
  }

  return (
    <Fragment>
      <Typography variant="h5">
        Edit category structure for {catalogName}:
      </Typography>
      <br />
      <Paper className={classes.root}>
        <Form
          onSubmit={onSubmit}
          formProps={{
            className: classes.form,
            noValidate: true,
            autoComplete: "off",
          }}
          gridProps={{
            spacing: 5,
          }}
          allowEmpty
        >
          <Text
            label={`Categories ${readOnlyCategories ? " (readonly)" : ""}`}
            field="categories"
            validations="required"
            size={5}
            multiline={true}
            rows={50}
            defaultValue={categoriesBuffer}
            InputProps={{
              className: classes.editor,
              readOnly: readOnlyCategories,
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <Text
            label="Forbidden categories"
            field="blacklist"
            size={5}
            multiline={true}
            rows={50}
            defaultValue={blacklistBuffer}
            InputProps={{ className: classes.editor }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <Text
            label={`Shopper Categories ${readOnlyCategories ? " (readonly)" : ""}`}
            field="shopperCategories"
            validations="required"
            size={5}
            multiline={true}
            rows={50}
            defaultValue={shopperCategoriesBuffer}
            InputProps={{
              className: classes.editor,
              readOnly: readOnlyCategories,
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <Grid item size={2}>
            <Typography variant="h5">Example: </Typography>
            <pre className={classes.example}>{exampleValue}</pre>
          </Grid>

          <Submit
            variant="contained"
            color="primary"
            className={classes.button}
            size={10}
            label="Save"
          />
        </Form>
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(CategoriesAdd);
