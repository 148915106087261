/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBuiltinServices = /* GraphQL */ `
  query GetBuiltinServices {
    getBuiltinServices {
      name
      image
      testService
      catalog {
        name
        editableCategories
      }
      site
      allowSiteSearch
      allowImageSearch
      allowGenericGrocery
      lockoutHours
    }
  }
`;
export const getBuiltinCatalogs = /* GraphQL */ `
  query GetBuiltinCatalogs {
    getBuiltinCatalogs {
      name
      editableCategories
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($sellerName: String!) {
    getCategories(sellerName: $sellerName) {
      catalog {
        name
        editableCategories
      }
      categories {
        name
      }
    }
  }
`;
export const getShopperCategories = /* GraphQL */ `
  query GetShopperCategories($sellerName: String!) {
    getShopperCategories(sellerName: $sellerName) {
      catalog {
        name
        editableCategories
      }
      categories {
        name
      }
    }
  }
`;
export const getCategoriesBlacklist = /* GraphQL */ `
  query GetCategoriesBlacklist($catalogName: String!) {
    getCategoriesBlacklist(catalogName: $catalogName) {
      catalog {
        name
        editableCategories
      }
      blacklist {
        name
      }
    }
  }
`;
export const getCategoriesImages = /* GraphQL */ `
  query GetCategoriesImages($catalogName: String!) {
    getCategoriesImages(catalogName: $catalogName) {
      catalog {
        name
        editableCategories
      }
      categoriesImages {
        category
        image
      }
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications {
    getNotifications {
      namespace
      severity
      startDate
      endDate
      subject
      body
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      allowed
      available
      categories
      shopperCategories
      description
      displaySize
      dumplingId
      featured
      image
      imageLink
      link
      name
      price
      pricePerUnit
      saleEndDate
      salePrice
      salePricePerUnit
      saleStartDate
      sellerId
      sellerName
      tags
      unitType
      upc
      weight
      weightUnit
      lastEditDate
      lastEditBy
      options {
        name
        choices
        required
        placeholderText
      }
      customizations {
        name
        maxLength
        required
        placeholderText
      }
      packageLineItems {
        productId
        name
        quantity
        note
        selectedProductOptions {
          name
          selection
        }
      }
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $name: String!
    $sellerName: String
    $filters: SearchFilters
    $order: String
    $top: Int
    $skip: Int
    $searchType: String
  ) {
    searchProducts(
      name: $name
      sellerName: $sellerName
      filters: $filters
      order: $order
      top: $top
      skip: $skip
      searchType: $searchType
    ) {
      products {
        id
        allowed
        available
        categories
        shopperCategories
        description
        displaySize
        dumplingId
        featured
        image
        imageLink
        link
        name
        price
        pricePerUnit
        saleEndDate
        salePrice
        salePricePerUnit
        saleStartDate
        sellerId
        sellerName
        tags
        unitType
        upc
        weight
        weightUnit
        lastEditDate
        lastEditBy
        options {
          name
          choices
          required
          placeholderText
        }
        customizations {
          name
          maxLength
          required
          placeholderText
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      count
      top
      skip
      searchTerms
    }
  }
`;
