import { useState } from "react";
import { Grid, Checkbox, FormControlLabel, InputLabel, FormControl, TextField, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Chip } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";

import validate from "./validate";


function Customizations(props) {
  const {
    className,
    field,
    label,
    onChange,
    onFormChange,
    onValidationError,
    options,
    required,
    size,
    validations,
    value,
    disabled,
  } = props;
  
  const [error, setError] = useState(null);
  const [defaultValue, setDefaultValue] = useState(value || []);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    name: '',
    placeholderText: '',
    required: true
  });
  const [dialogValueIndex, setDialogValueIndex] = useState(null);  // the index of the option they are editing
  const [dialogNameError, setDialogNameError]  = useState(false);
  const [dialogNameErrorMessage, setDialogNameErrorMessage] = useState("")


  // Crude validation of the dialog when entering a new option
  const handleDiaglogValidation = () => {
    var hasFormErrors = false
    if (dialogValue.name.trim() == "") {
      setDialogNameError(true)
      setDialogNameErrorMessage("Name must not be blank.")
      hasFormErrors = true
    }
    return hasFormErrors
  }

  const handleCreateOption = (event) => {
    event.preventDefault();
    // Crude validation check for empty name
    const hasFormErrors = handleDiaglogValidation()
    if (hasFormErrors) {
      return
    }
    const newDefaultValue = [
      ...defaultValue,
      {
        name: dialogValue.name,
        placeholderText: dialogValue.placeholderText,
        required: dialogValue.required
      }
    ]
    handleCloseDialog();
    handleValidation(newDefaultValue)
  };

  const handleEditOption = (event) => {
    event.preventDefault();
    // Crude validation check for empty name
    const hasFormErrors = handleDiaglogValidation()
    if (hasFormErrors) {
      return
    }
    const newDefaultValue = [
      ...defaultValue,
    ]
    newDefaultValue[dialogValueIndex] = dialogValue
    setDialogValueIndex(null)
    handleCloseDialog();
    handleValidation(newDefaultValue)
  };

  const handleCloseDialog = () => {
    setDialogNameError(false) // reset any errors when the change something
    setDialogNameErrorMessage("")
    toggleOpen(false);
  };

  function handleOptionClick(event, option, index) {
    setDialogValue({
      name: option.name,
      placeholderText: option.placeholderText,
      required: option.required
    });
    setDialogValueIndex(index)
    toggleOpen(true);
  }

  function onLocalChange(event, newValues) {
    // TODO instead of looking at the size change I could instead look at the event which says if they created or removed but this is working so do mess with it
    // If the new values increase in size, put up the dialog to add/edit an option
    if (newValues.length > defaultValue.length) {
      const newValue = newValues[newValues.length - 1]
      if (typeof newValue === 'string') {   // user is typing in a new Option
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setDialogValue({
            name: newValue,
            required: true,
            placeholderText: "Select"
          });
          toggleOpen(true);
        });
      } else {  // user has picked one of the default options
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setDialogValue({
            name: newValue.name,
            required: newValue.required,
            placeholderText: newValue.placeholderText
          });
          toggleOpen(true);
        });
      }
    } else { // They must have deleted an option so just write the change
      handleValidation(newValues)
    }
  }

  // Sends changes back up to the top level change
  function handleValidation(updatedValue) {
    const errors = validate(updatedValue, validations);

    if (errors) {
      setError(errors);
      onValidationError(field, errors);
    } else {
      setError(null);
      setDefaultValue(updatedValue);
      if (onFormChange) onFormChange(field, updatedValue);
      if (onChange) onChange(field, updatedValue);
    }
  }

  //const filter = createFilterOptions();

  return (
    <Grid item xs={size}>
      {/* <FormControl  // I don't entirely understand this form control and it's messing with the margins
        className={className}
        error={!!error}
        disabled={!!disabled}
        required={!!required}
      > */}
        {/* <InputLabel shrink htmlFor={label}>
          {label}
        </InputLabel> */}
        <Autocomplete
          id={field}
          size="small"
          multiple={true}
          value={defaultValue}
          onChange={onLocalChange}
          // This was a methed to add "Add XXX" to the dropdown when typing in something new but it just complicates things
          // filterOptions={(options, params) => {
          //   const filtered = filter(options, params);

          //   // Suggest the creation of a new value
          //   if (params.inputValue !== '') {
          //     filtered.push({
          //       inputValue: params.inputValue,
          //       name: `Add "${params.inputValue}"`,
          //     });
          //   }

          //   return filtered;
          // }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={options}
          getOptionLabel={(option) => {
            return option.name;
          }}
          renderOption={(option) => option.label}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Customizations" variant="outlined" error={!!error} required={!!required} disabled={!!disabled}/>
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} label={option.name} onClick={(event) => {handleOptionClick(event, option, index)}}/>
            ));
          }}
        />
        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="add-edit-option" fullWidth={true}>
          <form >
            <DialogTitle id="add-edit-option">Add/Edit Customization</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                Enter the choices for the given option.
              </DialogContentText> */}
              <TextField
                id="name"
                value={dialogValue.name}
                onChange={(event) => {
                  setDialogValue({ ...dialogValue, name: event.target.value })
                  setDialogNameError(false) // reset any errors when the change something
                  setDialogNameErrorMessage("")
                }}
                label="Name"
                type="text"
                autoComplete="off"
                style={{ paddingBottom: "30px", paddingRight: "50px" }}
                required={true}
                error={dialogNameError}
                helperText={dialogNameErrorMessage}
              />
              <TextField
                id="placeholderText"
                value={dialogValue.placeholderText}
                onChange={(event) => {
                  setDialogValue({ ...dialogValue, placeholderText: event.target.value })
                }}
                label="Placeholder Text"
                type="text"
                autoComplete="off"
                fullWidth
                style={{ paddingBottom: "30px" }}
              />
              <FormControlLabel
                label="Required"
                control= {
                  <Checkbox 
                    checked={dialogValue.required}
                    label={"Required"}
                    id="required"
                    onChange={(event) => {
                      setDialogValue({ ...dialogValue, required: event.target.checked })
                    }}
                    color="primary"
                  />
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              {dialogValueIndex == null ? (
                <Button onClick={handleCreateOption} color="primary">
                  Add
                </Button>
              ) : (
                <Button onClick={handleEditOption} color="primary">
                  Edit
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      {/* </FormControl> */}
    </Grid>
  );
}

Customizations.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  options: PropTypes.array,
  size: PropTypes.number,
};

export default Customizations;
