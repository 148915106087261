import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    maxWidth: 300,
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    height: 140,
  },
  description: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
  },
  cardImage: {
    //    width: 345,
    maxHeight: 150,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  selected: {
    border: "1px solid blue",
  },
});

function formatProductName(name) {
  if (name.length > 25) {
    return name.substring(0, 22) + " ...";
  } else {
    return name;
  }
}

function BulkCard(props) {
  const { product, classes, selected, onClick } = props;

  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const cardClasses = classes.root + " " + (selected ? classes.selected : "");

  return (
    <Card className={cardClasses}>
      <CardHeader
        classes={{ title: classes.cardHeaderTitle }}
        className={classes.cardHeader}
        title={formatProductName(product.name)}
        subheader={product.sellerName}
        onClick={() => onClick(product)}
      />
      <CardContent onClick={() => onClick(product)} style={{ padding: "8px" }}>
        <img
          className={classes.cardImage}
          alt={product.name}
          src={product.image || product.imageLink || "../image-na.jpg"}
        />
        <Typography
          variant="body1"
          color="textPrimary"
          component="p"
          className={classes.description}
        >
          {formatter.format(product.price / 100.0)}{" "}
          {product.allowed ? "" : " - Banned"}{" "}
          {product.available ? "" : " - Unavailable"}
        </Typography>
        <br />
        <Typography variant="caption" color="textPrimary" component="p">
          {product.categories.length > 0
            ? product.categories[product.categories.length - 1].replace(
                /\|/g,
                " > "
              )
            : "Uncategorized"}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(BulkCard);
