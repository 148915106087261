import { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { 
    BtnRedo,
    BtnUndo,
    BtnBold, 
    BtnItalic, 
    BtnUnderline,
    BtnStrikeThrough,
    BtnNumberedList,
    BtnBulletList,
    BtnLink,
    BtnClearFormatting,
    HtmlButton,
    Editor, 
    EditorProvider, 
    Toolbar,
    Separator
  } from 'react-simple-wysiwyg';

import PropTypes from "prop-types";

import validate from "./validate";

function HTMLEditor(props) {
  const [error, setError] = useState(null);

  const {
    disabled,
    field,
    filter,
    formatter,
    label,
    onChange,
    onFormChange,
    onValidationError,
    required,
    size,
    validations,
    value,
    ...rest
  } = props;

  const [displayValue, setDisplayValue] = useState(value);

  function onLocalChange(event) {
    const value = filter ? filter(event.target.value) : event.target.value;
    const errors = validate(value, validations);  // TODO - not currently doing any validations of the html

    if (errors) {
      setError(errors);
      onValidationError(field, errors);
    } else {
      setError(null);
      if (onFormChange) onFormChange(field, value);
      if (onChange) onChange(field, value);
      setDisplayValue(value)
    }
  }

  return (
    <Grid item xs={size} >
      <div style={{ paddingLeft: "8px"}}>
        <Typography >{label}</Typography>
        <EditorProvider>
            <Editor value={displayValue} onChange={onLocalChange} >
                <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnLink />
                    <BtnClearFormatting />
                    <HtmlButton />
                </Toolbar>
            </Editor>
        </EditorProvider>
      </div>
    </Grid>
  );
}

HTMLEditor.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  size: PropTypes.number,
};

export default HTMLEditor;