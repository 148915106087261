import { ThemeProvider } from "@material-ui/core/styles";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Routes from "./Routes";
import Theme from "./Theme";

import "./App.css";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Layout>
          <Routes />
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: "Email",
      key: "username",
      required: true,
      placeholder: "Email",
      type: "email",
      displayOrder: 1,
    },
    {
      label: "Password",
      key: "password",
      required: true,
      placeholder: "Password",
      type: "password",
      displayOrder: 2,
    },
  ],
};

export default withAuthenticator(App, {
  includeGreetings: false,
  signUpConfig,
});
