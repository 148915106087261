import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AutocompleteText from "../Form/AutocompleteText";
import {
  categories2psv,
  parseCategoryString,
  generateCategoryValue,
} from "../../lib/product";

import DimSum from "../../api/DimSum";

function CategorySelection(props) {
  const { isInput, onChange, onFormChange, split, store, value, multiple, ...rest } =
    props;

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    DimSum.categories(store)
      .then(({ catalog, categories }) => {
        setCategories(categories2psv(categories, !!split).sort());
      })
      .catch((response) => {
        setCategories([]);
      });
    // eslint-disable-next-line
  }, [store]);

  function onLocalChange(key, value) {
    const parsed = parseCategoryString(value);
    if (onChange) onChange(key, parsed);
    if (onFormChange) onFormChange(key, parsed);
  }

  return (
    <AutocompleteText
      onChange={onLocalChange}
      value={generateCategoryValue(value)}
      multiple={multiple}
      options={categories}
      {...rest}
    />
  );
}

CategorySelection.propTypes = {
  store: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  options: PropTypes.array,
  size: PropTypes.number,
  isInput: PropTypes.bool,
  multiple: PropTypes.bool
};

CategorySelection.defaultProps = {
  onChange: () => {},
};

export default CategorySelection;
