import { Fragment, useState, useEffect, useRef, useContext } from "react";
import {
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";

import BulkEditForm from "../components/BulkEditForm";
import BulkGrid from "../components/BulkGrid";
import BulkSearchBar from "../components/BulkSearchBar";
import EditBar from "../components/EditBar";
import ProductTable from "../components/ProductTable";

import DimSum from "../api/DimSum";

import { generateCategoryValue } from "../lib/product";

import NotificationContext from "../contexts/NotificationContext";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  formControl: {
    margin: theme.spacing(2),
    width: "35ch",
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    verticalAlign: "bottom",
    marginBottom: 15,
  },
  paginationControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    marginTop: theme.spacing(2),
  },
});

const DEFAULT_ITEMS_PER_PAGE = 500;

const initialSearchParams = {
  name: "",
  sellerName: "",
  skip: 0,
  top: DEFAULT_ITEMS_PER_PAGE,
  allowed: "",
  available: "",
  category: "",
  image: "",
  order: "",
  searchType: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function BulkEdit(props) {
  const { classes } = props;

  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [currentSearch, setCurrentSearch] = useState(initialSearchParams);
  const firstRun = useRef(true);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [availableServices, setAvailableServices] = useState({});

  // Products SUCCESSFULLY saved
  const [savedProducts, setSavedProducts] = useState([]);
  // Products NOT saved
  const [erroredProducts, setErroredProducts] = useState([]);

  const notifications = useContext(NotificationContext);

  useEffect(() => {
    setLoading(true);

    DimSum.builtinServices()
      .then((services) => {
        setAvailableServices(services);
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "Failed to load available services, please refresh."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (firstRun.current === true) {
      firstRun.current = false;
      return;
    }
    setLoading(true);

    const filters = {};

    if (currentSearch.category !== "")
      filters.category = generateCategoryValue(currentSearch.category);

    if (currentSearch.allowed !== "")
      filters.allowed = currentSearch.allowed === "true";

    if (currentSearch.available !== "")
      filters.available = currentSearch.available === "true";

    if (currentSearch.image !== "")
      filters.image = currentSearch.image === "true";

    DimSum.productSearch(
      currentSearch.name,
      availableServices[currentSearch.sellerName]
        ? availableServices[currentSearch.sellerName].catalog.name
        : "",
      currentSearch.top,
      currentSearch.skip,
      currentSearch.searchType || "",
      filters,
      currentSearch.order,
    )
      .then((results) => {
        setSearchResults(results);
        setSelectedTab(0);
        setSavedProducts([]);
        setErroredProducts([]);
      })
      .catch((response) => {
        notifications.setErrorMessage(
          "There was a problem when calling `searchProducts`."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [currentSearch]);

  function saveChanges(form) {
    setLoading(true);

    const changes = {};
    const ids = Object.keys(selectedProducts);

    if (form.hasOwnProperty('allowed') && typeof form['allowed'] === 'string' && form['allowed'].trim() !== '') {
    //if (form.allowed != null && form.allowed !== "") {
      changes.allowed = form.allowed;
    }

    if (form.available != null && form.available !== "") {
      changes.available = form.available;
    }

    if (form.imageLink != null && form.imageLink !== "") {
      changes.imageLink = form.imageLink;
    }

    if (form.hasOwnProperty('tags') && Array.isArray(form['tags']) && form['tags'].length > 0) {
      changes.tags = form.tags;
    }

    if (form.hasOwnProperty('categories') && Array.isArray(form['categories']) && form['categories'].length > 0) {
      changes.categories = form.categories;
    }

    if (form.hasOwnProperty('shopperCategories') && Array.isArray(form['shopperCategories']) && form['shopperCategories'].length > 0) {
      changes.shopperCategories = form.shopperCategories;
    }

    if (form.price != null && form.price !== "") {
      changes.price = form.price;
    }

    if (form.pricePercentChange != null && form.pricePercentChange !== "") {
      changes.pricePercentChange = form.pricePercentChange;
    }

    if (Object.keys(changes).length > 0) {
      DimSum.productBulkUpdate(ids, changes)
        .then((processed) => {
          let failed = [], saved = [];

          for (const idx in processed) {
            const productStatus = processed[idx];
            if (productStatus.status !== 'Success')
              failed.push(productStatus.product);
            else
              saved.push(productStatus.product);
          }

          setErroredProducts(failed);
          setSavedProducts(saved);

          /*
          setSearchResults({});
          setCurrentSearch(initialSearchParams);
          setSelectedTab(0);
          */
          setSelectedProducts({});

          notifications.setSuccessMessage("Successfully updated the products.");
        })
        .catch((response) => {
          console.error(response)
          notifications.setErrorMessage(
            "There was an error while trying to do bulk update!"
          );
        })
        .finally(() => setLoading(false));
    } else {
      // Nothing to do
      setLoading(false);
    }
  }

  function handleChange(event, newValue) {
    setSelectedTab(newValue);
  }

  function changePage(e, value) {
    e.preventDefault();

    setCurrentSearch({
      ...currentSearch,
      skip: (value - 1) * currentSearch.top,
    });
  }

  function onSearch(params) {
    setCurrentSearch({ ...currentSearch, ...params, skip: 0 });
  }

  function onSelect(product) {
    let selected = { ...selectedProducts };

    // If product is already selected remove it.
    if (selected[product.id]) {
      delete selected[product.id];
    } else {
      selected[product.id] = product;
    }

    setSelectedProducts(selected);
  }

  function onSelectVisible() {
    if (searchResults && searchResults.products) {
      let selected = {};

      searchResults.products.forEach((p) => (selected[p.id] = p));

      setSelectedProducts({ ...selectedProducts, ...selected });
    }
  }

  function onUnselectVisible() {
    if (searchResults && searchResults.products) {
      let selected = { ...selectedProducts };

      searchResults.products.forEach((p) => delete selected[p.id]);

      setSelectedProducts(selected);
    }
  }

  function onUnselectAll() {
    setSelectedProducts({});
  }

  function getStoreName() {
    const stores = new Set(
      Object.values(selectedProducts).map((p) => p["sellerName"])
    );

    if (stores.size > 1 || stores.size === 0) {
      return null;
    }

    return new Array(...stores)[0];
  }

  return (
    <Fragment>
      <BulkSearchBar
        services={availableServices}
        onSearch={onSearch}
        searchParams={currentSearch}
        searchTerms={searchResults.searchTerms}
      />
      {searchResults.count > 0 && (
        <Fragment>
          <br />

          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label="Product List"></Tab>
            <Tab label="Edits" />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <EditBar
              selected={selectedProducts}
              onSelectVisible={onSelectVisible}
              onUnselectVisible={onUnselectVisible}
              onUnselectAll={onUnselectAll}
            />
            <br />
            <BulkGrid
              products={searchResults.products}
              onSelect={onSelect}
              selected={selectedProducts}
            />
            <Pagination
              className={classes.paginationControl}
              variant="outlined"
              color="primary"
              count={Math.ceil(searchResults.count / searchResults.top)}
              page={Math.ceil(searchResults.skip / searchResults.top) + 1}
              onChange={changePage}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            {Object.keys(selectedProducts).length === 0 ? (
              <Typography variant="h6">No products selected.</Typography>
            ) : (
              <BulkEditForm onSubmit={saveChanges} store={getStoreName()} />
            )}
            {erroredProducts.length > 0 ? (
              <div className={classes.container}>
                <Typography variant="h5">
                  Errored Products ({erroredProducts.length})
                </Typography>
                <Fragment>
                  <ProductTable
                    products={erroredProducts}
                  />
                </Fragment>
              </div>
            ) : ''}
            {savedProducts.length > 0 ? (
              <div className={classes.container}>
                <Typography variant="h5">
                  Saved Products ({savedProducts.length})
                </Typography>
                <Fragment>
                  <ProductTable
                    products={savedProducts}
                  />
                </Fragment>
              </div>
            ) : ''}
          </TabPanel>
        </Fragment>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(BulkEdit);
