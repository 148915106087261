/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addProduct = /* GraphQL */ `
  mutation AddProduct($input: ProductAdd!) {
    addProduct(input: $input) {
      id
      allowed
      available
      categories
      shopperCategories
      description
      displaySize
      dumplingId
      featured
      image
      imageLink
      link
      name
      price
      pricePerUnit
      saleEndDate
      salePrice
      salePricePerUnit
      saleStartDate
      sellerId
      sellerName
      tags
      unitType
      upc
      weight
      weightUnit
      lastEditDate
      lastEditBy
      options {
        name
        choices
        required
        placeholderText
      }
      customizations {
        name
        maxLength
        required
        placeholderText
      }
      packageLineItems {
        productId
        name
        quantity
        note
        selectedProductOptions {
          name
          selection
        }
      }
    }
  }
`;
export const bulkAdd = /* GraphQL */ `
  mutation BulkAdd($input: [ProductAdd]!) {
    bulkAdd(input: $input) {
      product {
        id
        allowed
        available
        categories
        shopperCategories
        description
        displaySize
        dumplingId
        featured
        image
        imageLink
        link
        name
        price
        pricePerUnit
        saleEndDate
        salePrice
        salePricePerUnit
        saleStartDate
        sellerId
        sellerName
        tags
        unitType
        upc
        weight
        weightUnit
        lastEditDate
        lastEditBy
        options {
          name
          choices
          required
          placeholderText
        }
        customizations {
          name
          maxLength
          required
          placeholderText
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      status
    }
  }
`;
export const bulkUpdate = /* GraphQL */ `
  mutation BulkUpdate($ids: [String]!, $input: BulkUpdate!) {
    bulkUpdate(ids: $ids, input: $input) {
      product {
        id
        allowed
        available
        categories
        shopperCategories
        description
        displaySize
        dumplingId
        featured
        image
        imageLink
        link
        name
        price
        pricePerUnit
        saleEndDate
        salePrice
        salePricePerUnit
        saleStartDate
        sellerId
        sellerName
        tags
        unitType
        upc
        weight
        weightUnit
        lastEditDate
        lastEditBy
        options {
          name
          choices
          required
          placeholderText
        }
        customizations {
          name
          maxLength
          required
          placeholderText
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      status
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($id: String!, $input: ProductUpdate!) {
    updateProduct(id: $id, input: $input) {
      id
      allowed
      available
      categories
      shopperCategories
      description
      displaySize
      dumplingId
      featured
      image
      imageLink
      link
      name
      price
      pricePerUnit
      saleEndDate
      salePrice
      salePricePerUnit
      saleStartDate
      sellerId
      sellerName
      tags
      unitType
      upc
      weight
      weightUnit
      lastEditDate
      lastEditBy
      options {
        name
        choices
        required
        placeholderText
      }
      customizations {
        name
        maxLength
        required
        placeholderText
      }
      packageLineItems {
        productId
        name
        quantity
        note
        selectedProductOptions {
          name
          selection
        }
      }
    }
  }
`;
export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories($input: CategoryUpdate!) {
    updateCategories(input: $input)
  }
`;
export const updateShopperCategories = /* GraphQL */ `
  mutation UpdateShopperCategories($input: CategoryUpdate!) {
    updateShopperCategories(input: $input)
  }
`;
export const updateCategoriesBlacklist = /* GraphQL */ `
  mutation UpdateCategoriesBlacklist($input: CategoryBlacklistUpdate!) {
    updateCategoriesBlacklist(input: $input)
  }
`;
export const updateCategoryImage = /* GraphQL */ `
  mutation UpdateCategoryImage($input: CategoryImageUpdate!) {
    updateCategoryImage(input: $input)
  }
`;
export const putBuiltInService = /* GraphQL */ `
  mutation PutBuiltInService($input: ServicePut!) {
    putBuiltInService(input: $input) {
      ... on Service {
        name
        image
        testService
        catalog {
          name
          editableCategories
        }
        site
        allowSiteSearch
        allowImageSearch
        allowGenericGrocery
        lockoutHours
      }
      ... on Error {
        errors
      }
    }
  }
`;
export const setFeaturedProduct = /* GraphQL */ `
  mutation SetFeaturedProduct($id: String!, $featured: Boolean!) {
    setFeaturedProduct(id: $id, featured: $featured) {
      id
      allowed
      available
      categories
      shopperCategories
      description
      displaySize
      dumplingId
      featured
      image
      imageLink
      link
      name
      price
      pricePerUnit
      saleEndDate
      salePrice
      salePricePerUnit
      saleStartDate
      sellerId
      sellerName
      tags
      unitType
      upc
      weight
      weightUnit
      lastEditDate
      lastEditBy
      options {
        name
        choices
        required
        placeholderText
      }
      customizations {
        name
        maxLength
        required
        placeholderText
      }
      packageLineItems {
        productId
        name
        quantity
        note
        selectedProductOptions {
          name
          selection
        }
      }
    }
  }
`;
