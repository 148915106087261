import { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactJson from 'react-json-view'

import PropTypes from "prop-types";

import validate from "./validate";

function JSONEditor(props) {
  const [error, setError] = useState(null);

  const {
    disabled,
    field,
    filter,
    formatter,
    label,
    onChange,
    onFormChange,
    onValidationError,
    required,
    size,
    validations,
    value,
    ...rest
  } = props;

  const [displayValue, setDisplayValue] = useState(value);

  function onLocalChange(event) {
    console.log(event)
    const value = filter ? filter(event.updated_src) : event.updated_src;
    console.log(value)
    const errors = validate(value, validations);  // TODO - not currently doing any validations of the html

    if (errors) {
      setError(errors);
      onValidationError(field, errors);
    } else {
      setError(null);
      if (onFormChange) onFormChange(field, value);
      if (onChange) onChange(field, value);
      setDisplayValue(value)
    }
  }

  return (
    <Grid item xs={size} >
      <div style={{ paddingLeft: "8px"}}>
        <Typography style={{paddingBottom: "8px"}} >{label}</Typography>
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={true}
          src={displayValue}
          onEdit={(e) => {
            onLocalChange(e)
          }}
          onAdd={(e) => {
            onLocalChange(e)
          }}
          onDelete={(e) => {
            onLocalChange(e)
          }}
        />
      </div>
    </Grid>
  );
}

JSONEditor.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  size: PropTypes.number,
};

export default JSONEditor;