import { useState } from "react";
import { Grid, InputLabel, FormControl, NativeSelect } from "@material-ui/core";
import PropTypes from "prop-types";

import validate from "./validate";

function Selection(props) {
  const {
    className,
    field,
    label,
    onChange,
    onFormChange,
    onValidationError,
    options,
    required,
    size,
    validations,
    value,
    disabled,
  } = props;

  const [error, setError] = useState(null);
  const [defaultValue, setDefaultValue] = useState(value);

  function onLocalChange(event) {
    const value = event.target.value;
    const errors = validate(value, validations);

    if (errors) {
      setError(errors);
      onValidationError(field, errors);
    } else {
      setError(null);
      setDefaultValue(value);
      if (onFormChange) onFormChange(field, value);
      if (onChange) onChange(field, value);
    }
  }

  return (
    <Grid item xs={size}>
      <FormControl
        className={className}
        error={!!error}
        disabled={!!disabled}
        required={!!required}
      >
        <InputLabel shrink htmlFor={label}>
          {label}
        </InputLabel>
        <NativeSelect
          value={defaultValue}
          onChange={onLocalChange}
          inputProps={{
            name: field,
            id: field,
          }}
        >
          {options.map((pair, idx) => (
            <option key={idx} value={pair[0]}>
              {pair[1]}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Grid>
  );
}

Selection.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  options: PropTypes.array,
  size: PropTypes.number,
};

export default Selection;
