import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Fragment, useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withStyles } from "@material-ui/core/styles";
import {
  //  DateTimeRange,
  AutocompleteText,
  Check,
  Form,
  Number,
  Selection,
  Submit,
  Text,
  Upload,
  Options,
  Customizations,
  HTMLEditor
} from "../components/Form";
import CategorySelection from "../components/Categories/CategorySelection";

import { centsToDollars, dollarsToCents } from "../lib/product";

import NotificationContext from "../contexts/NotificationContext";
import DimSum from "../api/DimSum";

import config from "../aws-exports";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    maxWidth: 800,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  productImage: {
    height: 100,
    width: "auto",
  },
  imageContainer: {
    textAlign: "center",
    align: "center",
  },
  formControl: {
    margin: theme.spacing(1),
  },
});

const boostingTags = ["boost_1", "boost_2", "boost_3"];

const initialParams = {
  name: "",
  price: "",
  imageLink: "",
  displaySize: "",
  sellerName: "",
};

function ProductAdd(props) {
  const { classes } = props;

  // State flags.
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState("");
  const [stores, setStores] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const notifications = useContext(NotificationContext);

  const defaultValues = objectFromQuery();

  useEffect(() => {
    setLoading(true);

    DimSum.builtinCatalogs()
      .then((catalogs) => {
        const allCatalogs = catalogs.map((catalog) => catalog.name).sort();

        setStores(allCatalogs);

        if (allCatalogs.includes(defaultValues.sellerName) && store === "") {
          setStore(defaultValues.sellerName);
        } else if (store === "") {
          setStore(allCatalogs[0]);
        }
      })
      .catch((_response) => {
        setStores([]);
        notifications.setErrorMessage(
          "Failed to load catalogs list, please refresh."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form) {
    setLoading(true);

    if (form.imageUpload) {
      try {
        const ext = form.imageUpload.name
          .substr(form.imageUpload.name.lastIndexOf(".") + 1)
          .toLowerCase();
        const key = `dimsum/images/uploads/product_images/${uuidv4()}.${ext}`;

        await Storage.put(key, form.imageUpload, {
          contentType: form.imageUpload.type,
        });

        form.imageLink = `https://${config.Storage.AWSS3.bucket}.s3.${config.Storage.AWSS3.region}.amazonaws.com/public/${key}`;
      } catch (error) {
        notifications.setErrorMessage(`Error uploading file: ${error}`);
        setLoading(false);
        return;
      } finally {
        delete form.imageUpload;
      }
    }

    DimSum.addProduct(form)
      .then((product) => {
        notifications.setSuccessMessage("Product successfully added!");

        history.push(`/product/${product.id}`);
      })
      .catch((_response) => {
        console.error(_response);
        notifications.setErrorMessage("Failed to add the product.");
      })
      .finally(() => setLoading(false));
  }

  function generateStores() {
    return stores.map((store) => [store, store]);
  }

  function objectFromQuery() {
    let params = {};

    Object.keys(initialParams).forEach((key) => {
      if (query.has(key) && query.get(key) !== "null") {
        params[key] = decodeURIComponent(query.get(key));
      }
    });

    return params;
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5">Adding product:</Typography>
        <br />
        <Form
          initialValues={defaultValues}
          onSubmit={onSubmit}
          formProps={{
            className: classes.form,
            noValidate: true,
            autoComplete: "off",
          }}
          gridProps={{
            spacing: 3,
          }}
        >
          <Text
            field="name"
            label="Name"
            size={10}
            validations="required"
            required
          />

          <Selection
            field="sellerName"
            label="Store"
            size={5}
            options={generateStores()}
            onChange={(k, v) => setStore(v)}
            className={classes.formControl}
            value={store}
            required
          />
          <Number
            field="price"
            label="Price"
            size={5}
            validations="notNegative"
            prefix="$"
            formatter={centsToDollars}
            filter={dollarsToCents}
          />

          <Check field="allowed" label="Allowed" size={5} value={true}/>
          <Check field="available" label="Available" size={5} />

          <AutocompleteText
            field="tags"
            label="Tags"
            size={10}
            allowNonMembers
            multiple
            options={boostingTags}
            //className={classes.formControl}
          />

          <CategorySelection
            label="Categories"
            field="categories"
            store={store}
            size={10}
            isInput={true}
            //className={classes.formControl}
            required
            validations="required"
            multiple={true}
            value={[]} // when multiple is true, value can't be null
            split
          />
          <Options
            field="options"
            label="Options"
            size={10}
            options={
              [{'name': 'Type', 'choices': ['Organic', 'Non-Organic'], 'placeholderText': 'Select', 'required': true}, {'name': 'Variety', 'choices':[], 'placeholderText': 'Select', 'required': true}, {'name': 'Size', 'choices':[], 'placeholderText': 'Select', 'required': true}]}
            //className={classes.formControl}
          />
          <Customizations
            field="customizations"
            label="Customizations"
            size={10}
            options={
              [{'name': 'Notes', 'label': 'Notes - Optional', 'placeholderText': 'Optionally provide more details about this item for our team.', 'required': false}, {'name': 'Notes', 'label': 'Custom Item - Required', 'placeholderText': 'Not seeing your product in our store? Write in your item and we will do our best to find it!', 'required': true}]
            }
            //className={classes.formControl}
          />
          <Text field="description" label="Description" size={10} />
          <HTMLEditor field="description" label="Description" size={10} />
          <Text field="imageLink" label="Image Link" size={7} />
          <Upload
            size={3}
            field="imageUpload"
            color="default"
            variant="contained"
            label="Upload image"
            startIcon={<CloudUploadIcon />}
          />
          <Text field="link" label="Product Link" size={10} />

          <Text field="sellerId" label="Seller ID" size={5} />
          <Text field="upc" label="UPC" size={5} />
          <Text field="dumplingId" label="Dumpling ID" size={10} />

          <Selection
            field="unitType"
            label="Unit Type"
            size={5}
            options={[
              ["", "None"],
              ["EACH", "Each"],
              ["WEIGHT", "Weight"],
            ]}
            className={classes.formControl}
          />
          <Number
            field="pricePerUnit"
            label="Price per Unit"
            size={5}
            validations="notNegative"
            prefix="$"
            formatter={centsToDollars}
            filter={dollarsToCents}
          />

          <Number
            field="salePrice"
            label="Sale Price"
            size={5}
            validations="notNegative"
            prefix="$"
            formatter={centsToDollars}
            filter={dollarsToCents}
          />
          <Number
            field="salePricePerUnit"
            label="Sale Price per Unit"
            size={5}
            validations="notNegative"
            prefix="$"
            formatter={centsToDollars}
            filter={dollarsToCents}
          />
          {/*
          <DateTimeRange
            fromField="saleStartDate"
            fromLabel="Sale Start Date"
            fromRequired={false}
            fullWidth={false}
            size={5}
            spacing={3}
            toField="saleEndDate"
            toLabel="Sale End Date"
            toRequired={false}
          />

           */}
          <Number
            field="weight"
            label="Weight"
            size={5}
            validations="notNegative"
          />
          <Text field="weightUnit" label="Weight Unit" size={5} />
          <Text field="displaySize" label="Display Size" size={10} />
          <Submit
            variant="contained"
            color="primary"
            className={classes.button}
            size={10}
            label="Save"
          />
        </Form>
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(ProductAdd);
