import DimSum from "../api/DimSum";

import { categories2psv } from "../lib/product";

class ProductValidator {
  static async fetchCategories(store) {
    try {
      const response = await DimSum.categories(store);

      this.categories = this.categories || {};

      this.categories[store] = categories2psv(response.categories, true);

      const response2 = await DimSum.shopperCategories(store);

      this.shopperCategories = this.shopperCategories || {};

      this.shopperCategories[store] = categories2psv(response2.categories, true);
    } catch {}
  }

  static valid_categories(product) {
    if (!this.categories || !this.categories[product.sellerName]) {
      return false;
    }

    return (
      Array.isArray(product.categories) &&
      product.categories.length > 0 &&
      this.categories[product.sellerName].includes(
        product.categories[product.categories.length - 1]
      )
    );
  }

  static valid_shopper_categories(product) {
    // shopper categories are not requried
    if (!product.shopperCategories) {
      return true
    }
    
    if (!this.shopperCategories || !this.shopperCategories[product.sellerName]) {
      return false;
    }

    return (
      Array.isArray(product.shopperCategories) &&
      product.shopperCategories.length > 0 &&
      this.shopperCategories[product.sellerName].includes(
        product.shopperCategories[product.shopperCategories.length - 1]
      )
    );
  }
  
  static valid_options(product) {
    if (!product.options) {
      return true
    } else {
      return (Array.isArray(product.options))
    }
  }

  static valid_customizations(product) {
    if (!product.customizations) {
      return true
    } else {
      return (Array.isArray(product.customizations))
    }
  }

  static valid_price(product) {
    return !isNaN(parseFloat(product.price));
  }

  static valid_name(product) {
    return (
      !!product.name &&
      typeof product.name == "string" &&
      product.name.length > 0
    );
  }

  static valid_sellerName(product) {
    return (
      !!product.sellerName &&
      typeof product.sellerName == "string" &&
      product.sellerName.length > 0
    );
  }

  static validate(product) {
    let errors = [];

    if (!this.valid_categories(product)) errors.push("category not found");
    if (!this.valid_shopper_categories(product)) errors.push("invalid shopper category");
    if (!this.valid_price(product)) errors.push("invalid price");
    if (!this.valid_name(product)) errors.push("invalid product name");
    if (!this.valid_sellerName(product)) errors.push("invalid product store");
    if (!this.valid_options(product)) errors.push("invalid options");
    if (!this.valid_customizations(product)) errors.push("invalid customizaitons");

    return errors;
  }
}

export default ProductValidator;
