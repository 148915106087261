import { useState } from "react";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import PropTypes from "prop-types";

function Check(props) {
  const {
    disabled,
    field,
    label,
    onChange,
    onFormChange,
    size,
    value,
    ...rest
  } = props;

  const [checked, setChecked] = useState(!!value);

  function onLocalChange(event) {
    const value = !!event.target.checked;

    if (onFormChange) onFormChange(field, value);
    if (onChange) onChange(field, value);
    setChecked(value);
  }

  return (
    <Grid item xs={size}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            onChange={onLocalChange}
            checked={checked}
            name={field}
            id={field}
            color="primary"
          />
        }
        label={label}
        {...rest}
      />
    </Grid>
  );
}

Check.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  size: PropTypes.number,
};

export default Check;
