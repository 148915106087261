import { Fragment, useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

import { generateCategoryValue, generateOptionCustomizationValue, centsToDollars } from "../lib/product";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  invalid: {
    color: red[700],
    fontWeight: "600",
  },
});

function ProductTable(props) {
  const { classes, products, loading, validator } = props;

  const [rows, setRows] = useState([]);
  const [errorCount, setErrorCount] = useState(0);

  function buildValidatedRenderer(validator, errorMsg, formatter) {
    return (params) => {
      if (validator(params)) {
        return (
          <span>{formatter ? formatter(params.value) : params.value}</span>
        );
      } else {
        return (
          <span className={classes.invalid} title={errorMsg}>
            {params.value
              ? formatter
                ? formatter(params.value)
                : params.value
              : "N/A"}
          </span>
        );
      }
    };
  }

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    { field: "sellerId", headerName: "sellerId", width: 180 },
    {
      field: "name",
      headerName: "Name*",
      width: 250,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_name(row) : (row) => true,
        "Invalid product name."
      ),
    },
    { field: "description", headerName: "Description", width: 350 },
    {
      field: "price",
      headerName: "Price*",
      type: "number",
      width: 120,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_price(row) : (row) => true,
        "Invalid product price.",
        (value) => `$${centsToDollars(value)}`
      ),
    },
    {
      field: "categories",
      headerName: "Category*",
      width: 350,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_categories(row) : (row) => true,
        "Category not found.",
        (value) => generateCategoryValue(value)
      ),
    },
    {
      field: "shopperCategories",
      headerName: "Shopper Category",
      width: 350,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_shopper_categories(row) : (row) => true,
        "Shopper ategory not found.",
        (value) => generateCategoryValue(value)
      ),
    },
    { field: "imageLink", headerName: "Image Link", width: 350 },
    { field: "link", headerName: "Link", width: 350 },
    { field: "allowed", headerName: "Allowed*", width: 150 },
    { field: "available", headerName: "Available*", width: 150 },
    {
      field: "options",
      headerName: "Options",
      width: 350,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_options(row) : (row) => true,
        "Category not found.",
        (value) => generateOptionCustomizationValue(value)
      ),
    },
    {
      field: "customizations",
      headerName: "Customizations",
      width: 350,
      renderCell: buildValidatedRenderer(
        ({ row }) => validator ? validator.valid_customizations(row) : (row) => true,
        "Category not found.",
        (value) => generateOptionCustomizationValue(value)
      ),
    },
  ];

  useEffect(() => {
    if (products.length === 0) {
      setRows([]);
      setErrorCount(0);

      return;
    }

    let errors = 0,
      withErrors = [],
      withoutErrors = [];

    products.forEach((p, i) => {
      if (validator && validator.validate(p).length !== 0) {
        errors += 1;

        withErrors.push({ ...p, id: i });
      } else {
        withoutErrors.push({ ...p, id: i });
      }
    });

    // Once everything is loaded and ready to be validated, set the rows.
    setRows([...withErrors, ...withoutErrors]);
    setErrorCount(errors);
  }, [products, validator]);

  return (
    <Fragment>
      <Paper className={classes.root}>
        {errorCount > 0 && (
          <Typography variant="h6" color="secondary">
            {errorCount} products with errors found.
          </Typography>
        )}
        <p />
        <div style={{ maxHeight: 700, width: "100%" }}>
          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            disableColumnMenu={true}
            density="compact"
            loading={loading}
          />
        </div>
      </Paper>
    </Fragment>
  );
}

export default withStyles(styles)(ProductTable);
