import { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import BulkCard from "./BulkCard";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: "2em 1.1em",
  },
});

function BulkGrid(props) {
  const { classes, products, selected, onSelect } = props;

  return (
    <Fragment>
      <Paper className={classes.root}>
        {products.map((product, idx) => (
          <BulkCard
            key={idx}
            product={product}
            selected={!!selected[product.id]}
            onClick={onSelect}
          />
        ))}
      </Paper>
    </Fragment>
  );
}

export default withStyles(styles)(BulkGrid);
