import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Selection from "./Form/Selection";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    width: 250,
  },
  helpText: {
    marginTop: 30,
    marginLeft: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: "35ch",
  },
});

function CatalogSelector(props) {
  const { onChange, available, classes, ...rest } = props;

  return (
    <Paper className={classes.root}>
      <Selection
        label="Catalog"
        onChange={(key, value) => onChange(value)}
        options={available.map((catalog) => [catalog, catalog])}
        {...rest}
      />
    </Paper>
  );
}

export default withStyles(styles)(CatalogSelector);
