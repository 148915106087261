import { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";

import validate from "./validate";

function AutocompleteText(props) {
  const [error, setError] = useState(false);

  const {
    allowNonMembers,
    disabled,
    field,
    label,
    multiple,
    onChange,
    onFormChange,
    onValidationError,
    options,
    required,
    size,
    validations,
    value,
    ...rest
  } = props;

  function onLocalChange(event, value) {
    var errors = false
    if (multiple) {
      console.log("multiple", !allowNonMembers, !!validate(value, validations), (!value.every(v => options.includes(v)) && !allowNonMembers) )
      errors = !!validate(value, validations) ||
        (!value.every(v => options.includes(v)) && !allowNonMembers);
      console.log("the errors", errors)
    } else {
      errors = !!validate(value, validations) ||
        (!options.includes(value) && !allowNonMembers);
    }

    if (errors) {
      console.log("auto errors", errors)
      setError(errors);
      if (onValidationError) onValidationError(field, errors);
    } else {
      setError(null);
      if (onFormChange) onFormChange(field, value);
      if (onChange) onChange(field, value);
    }
  }

  function renderInput(params) {
    var included = false
    if (params.inputProps.value) {
      // I don't understand how this ever worked as the input is always just a string even if you can select multiple
      // if (multiple) {
      //   included = !params.inputProps.value.every(v => options.includes(v)) && !allowNonMembers;
      // } else {
      //  included = !options.includes(params.inputProps.value) && !allowNonMembers;
      // }
      included = !options.includes(params.inputProps.value) && !allowNonMembers;
    }

    return (
      <TextField
        {...params}
        label={label}
        error={!!error || included}
        required={!!required}
        disabled={!!disabled}
        variant="outlined"
      />
    );
  }

  return (
    <Grid item xs={size}>
      <Autocomplete
        size="small"
        multiple={multiple}
        id={field}
        options={options}
        freeSolo={options && options.length >= 0}
        defaultValue={value}
        onChange={onLocalChange}
        renderInput={renderInput}
        {...rest}
      />
    </Grid>
  );
}

AutocompleteText.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  size: PropTypes.number,
  multiple: PropTypes.bool
};

export default AutocompleteText;
