const devConfig = {
  //////////////////////////////////////////////////////////////////////////////
  // AppSync (GraphQL)
  //////////////////////////////////////////////////////////////////////////////
  aws_appsync_graphqlEndpoint:
    "https://mkmrbdttrfbf3mhlhpdkg77tnu.appsync-api.us-east-2.amazonaws.com/graphql",

  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "null",

  //////////////////////////////////////////////////////////////////////////////
  // Cognito (Auth)
  //////////////////////////////////////////////////////////////////////////////
  Auth: {
    mandatorySignIn: true,
    region: "us-east-2",
    userPoolId: "us-east-2_j5LPLW3JF",
    identityPoolId: "us-east-2:b2146a87-2766-41a4-9e4b-199bedb07fbf",
    userPoolWebClientId: "7f0q0rk9puvbfk7c95plimtgjj",
  },
  //////////////////////////////////////////////////////////////////////////////
  // S3
  //////////////////////////////////////////////////////////////////////////////
  Storage: {
    AWSS3: {
      bucket: "dev-green-dumplings",
      region: "us-east-2",
    },
  },
};

const prodConfig = {
  //////////////////////////////////////////////////////////////////////////////
  // AppSync (GraphQL)
  //////////////////////////////////////////////////////////////////////////////
  aws_appsync_graphqlEndpoint:
    "https://6plttzzgbnattjjqfifbgpdthe.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "null",

  //////////////////////////////////////////////////////////////////////////////
  // Cognito (Auth)
  //////////////////////////////////////////////////////////////////////////////
  Auth: {
    mandatorySignIn: true,
    region: "us-east-2",
    userPoolId: "us-east-2_m4bCaF5nD",
    identityPoolId: "us-east-2:23274543-c56a-4d30-a78a-379c900b350e",
    userPoolWebClientId: "2rm0e33p0ki3p8g20kivpt008e",
  },
  //////////////////////////////////////////////////////////////////////////////
  // S3
  //////////////////////////////////////////////////////////////////////////////
  Storage: {
    AWSS3: {
      bucket: "green-dumplings",
      region: "us-east-2",
    },
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prodConfig : devConfig;

export default config;
