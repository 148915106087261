import { Fragment, useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ProductSearchBar from "../components/ProductSearchBar";
import { withStyles } from "@material-ui/core/styles";
import ProductGrid from "../components/ProductGrid";

import DimSum from "../api/DimSum";
import NotificationContext from "../contexts/NotificationContext";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  formControl: {
    margin: theme.spacing(2),
    width: "35ch",
  },
  button: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    verticalAlign: "bottom",
    marginBottom: 15,
  },
  paginationControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const DEFAULT_ITEMS_PER_PAGE = 50;

const initialSearchParams = {
  name: "",
  sellerName: "",
  skip: 0,
  top: DEFAULT_ITEMS_PER_PAGE,
  allowed: "",
  available: "",
  emptyPrice: false,
  featured: "",
  category: "",
  image: "",
  order: "",
  searchType: "",
};

function Search(props) {
  const { classes } = props;

  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [availableServices, setAvailableServices] = useState({});

  const notifications = useContext(NotificationContext);

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  function objectToQuery(object, force) {
    Object.keys(object).forEach((key) => {
      if (force || !query.has(key) || query.get(key) === "") {
        query.set(key, encodeURIComponent(object[key]));
      }
    });
  }

  function objectFromQuery() {
    let params = {};

    Object.keys(initialSearchParams).forEach((key) => {
      params[key] = query.has(key)
        ? decodeURIComponent(query.get(key))
        : initialSearchParams[key];
    });

    return params;
  }

  // Search is realized on component mount from parameters from the query string.
  // We get the parametes from the query string or base parameter values.
  const currentSearch = objectFromQuery();

  useEffect(() => {
    setLoading(true);

    DimSum.builtinServices()
      .then((services) => {
        setAvailableServices(services);
      })
      .catch((response) => {
        console.error(response);
        notifications.setErrorMessage(
          "Failed to load available services, please refresh."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (query.toString() === "" || Object.keys(availableServices).length === 0)
      return;

    setLoading(true);

    const filters = {};

    if (currentSearch.category !== "")
      filters.category = currentSearch.category || "";

    if (currentSearch.allowed !== "")
      filters.allowed = currentSearch.allowed === "true";

    if (currentSearch.available !== "")
      filters.available = currentSearch.available === "true";

    if (currentSearch.image !== "")
      filters.image = currentSearch.image === "true";

    if (currentSearch.emptyPrice !== "")
      filters.emptyPrice = currentSearch.emptyPrice === "true";

    if (currentSearch.featured !== "")
      filters.featured = currentSearch.featured === "true";

    DimSum.productSearch(
      currentSearch.name,
      availableServices[currentSearch.sellerName]
        ? availableServices[currentSearch.sellerName].catalog.name
        : "",
      currentSearch.top,
      currentSearch.skip,
      currentSearch.searchType || "",
      filters,
      currentSearch.order || ""
    )
      .then((results) => {
        // Record search params in history so back button works.
        history.push(`${location.pathname}?${query.toString()}`);

        setSearchResults(results);
      })
      .catch((response) => {
        console.error(response)
        notifications.setErrorMessage(
          "Failed to search products, please refresh."
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [availableServices]);

  // Actual search is done by creating the query string and reloading the component with the
  // new parameters.
  function doSearch() {
    objectToQuery(initialSearchParams);

    //  Overwrite current location with search parameters.
    window.location = `${location.pathname}?${query.toString()}`;
  }

  function changePage(e, value) {
    e.preventDefault();

    query.set("skip", (value - 1) * query.get("top"));

    doSearch();
  }

  function changeDisplayCount(e) {
    query.set("top", e.target.value);

    doSearch();
  }

  function onSearch(params) {
    params["skip"] = 0;

    objectToQuery(params, true);

    doSearch();
  }

  return (
    <Fragment>
      <ProductSearchBar
        services={availableServices}
        onSearch={onSearch}
        searchParams={currentSearch}
        searchTerms={searchResults.searchTerms}
      />
      {searchResults.count > 0 && (
        <Fragment>
          <br />
          <ProductGrid products={searchResults.products} />
          <Pagination
            className={classes.paginationControl}
            variant="outlined"
            color="primary"
            count={Math.ceil(searchResults.count / searchResults.top)}
            page={Math.ceil(searchResults.skip / searchResults.top) + 1}
            onChange={changePage}
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Items per page:</InputLabel>
            <Select
              id="itemsPerPage"
              name="itemsPerPage"
              onChange={changeDisplayCount}
              defaultValue={currentSearch.top}
            >
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
          </FormControl>
        </Fragment>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(Search);
