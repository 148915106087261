// Miscellaneous product handling functions.

// Converts an array of pipe separated values to our category tree structure.
function psv2categories(list) {
  function insert(obj, pieces) {
    if (!Array.isArray(pieces) || pieces.length === 0) return;

    const current = pieces[0];
    let found = obj.subcategories.find((obj) => obj.name === current);

    if (!found) {
      found = { name: current, subcategories: [] };
      obj.subcategories.push(found);
    }

    insert(found, pieces.slice(1));
  }

  //const categories = Array(...new Set(list)).sort();
  // Stop the sorting of the categories so we can specify our own order.
  const categories = Array(...new Set(list));

  let root = { name: "", subcategories: [] };

  categories.forEach((category) => insert(root, category.split("|")));

  return root;
}

// Converts our category tree structure to an array of pipe separated values.
function categories2psv(root, split) {
  function iterator(obj) {
    const { name, subcategories } = obj;

    let results = [];

    const subcats = (subcategories || []).map((sub) => iterator(sub));

    if (split || subcats.length === 0) {
      results.push(name);
    }

    subcats.flat().forEach((sub) => results.push(`${name}|${sub}`));

    return results;
  }

  const { subcategories } = root;

  return subcategories.map((sub) => iterator(sub)).flat();
}

function parseCategoryString(value) {
  if (value == null || value === "") {
    return "";
  }

  // Try to parse this to see if it's an array
  let valueToParse = value
  try {
    valueToParse = JSON.parse(value)
  } catch {}

  var completed = [];
  if (Array.isArray(valueToParse)) {
    valueToParse.forEach((categoryPart) => {
      const [first, ...rest] = categoryPart.split("|").map((str) => {
        let processed = str.trim();
    
        // Quoted category, unquote.
        if (processed[0] === "'") {
          processed = processed.substr(1, processed.length - 1);
        }
    
        // Quoted category, unquote.
        if (processed[processed.length - 1] === "'") {
          processed = processed.substr(0, processed.length - 1);
        }
    
        return processed;
      });
    
      let accum = first;
      let processed = [first];
    
      rest.forEach((part) => {
        accum = `${accum}|${part}`;
        processed.push(accum);
      });

      completed.push.apply(completed, processed)
    });
  } else {
    const [first, ...rest] = valueToParse.split("|").map((str) => {
      let processed = str.trim();
  
      // Quoted category, unquote.
      if (processed[0] === "'") {
        processed = processed.substr(1, processed.length - 1);
      }
  
      // Quoted category, unquote.
      if (processed[processed.length - 1] === "'") {
        processed = processed.substr(0, processed.length - 1);
      }
  
      return processed;
    });
  
    let accum = first;
    let processed = [first];
  
    rest.forEach((part) => {
      accum = `${accum}|${part}`;
      processed.push(accum);
    });

    completed.push.apply(completed, processed)
  }

  // Remove dups before saving. (This will produce dups if you try to resave after it expands)
  const cleanCompleted = [... new Set(completed)]

  return cleanCompleted;
}

function generateCategoryValue(value) {
  if (value == null || value === "") {
    return null;
  }
  return value;
  // if (Array.isArray(value)) {
  //   return value[value.length - 1];
  // } else {
  //   return value;
  // }
}

function parseOptionCustomizationValue(value) {
  if (value == null || value === "") {
    return "";
  }

  // Try to parse this to see if it's an array
  let valueToParse = value;
  try {
    valueToParse = JSON.parse(value);
  } catch {
    return "";
  }
  return valueToParse;
}

function generateOptionCustomizationValue(value) {
  if (value == null || value === "") {
    return null;
  }
  return JSON.stringify(value);
}

function centsToDollars(value) {
  if (value) {
    return parseFloat(value) / 100.0;
  }
  return value;
}

function dollarsToCents(value) {
  if (value) {
    return parseInt(parseFloat(value) * 100.0);
  }
  return value;
}

export {
  categories2psv,
  psv2categories,
  centsToDollars,
  dollarsToCents,
  generateCategoryValue,
  parseCategoryString,
  parseOptionCustomizationValue,
  generateOptionCustomizationValue
};
