import { useState } from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";

import validate from "./validate";

function Number(props) {
  const [error, setError] = useState(null);

  const {
    disabled,
    field,
    filter,
    formatter,
    label,
    onChange,
    onFormChange,
    onValidationError,
    prefix,
    required,
    size,
    suffix,
    validations,
    value,
    ...rest
  } = props;

  function onLocalChange(event) {
    const value = filter ? filter(event.target.value) : event.target.value;
    const errors = validate(value, validations);

    if (errors) {
      setError(errors);
      onValidationError(field, errors);
    } else {
      setError(null);
      if (onFormChange) onFormChange(field, value);
      if (onChange) onChange(field, value);
    }
  }

  const adornments = {};

  if (prefix) {
    adornments["startAdornment"] = (
      <InputAdornment position="start">{prefix}</InputAdornment>
    );
  }

  if (suffix) {
    adornments["endAdornment"] = (
      <InputAdornment position="end">{suffix}</InputAdornment>
    );
  }

  return (
    <Grid item xs={size}>
      <TextField
        type="number"
        id={field}
        label={label}
        error={!!error}
        required={!!required}
        disabled={!!disabled}
        defaultValue={formatter ? formatter(value) : value}
        helperText={error}
        onChange={onLocalChange}
        InputProps={adornments}
        {...rest}
      />
    </Grid>
  );
}

Number.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onFormChange: PropTypes.func,
  onValidationError: PropTypes.func,
  size: PropTypes.number,
};

export default Number;
