import React from "react";
import { Route, Switch } from "react-router-dom";
import Add from "./pages/Add";
import BulkAdd from "./pages/BulkAdd";
import BulkEdit from "./pages/BulkEdit";
import CategoriesAdd from "./pages/CategoriesAdd";
import CategoriesImages from "./pages/CategoriesImages";
import CategoryImageUpload from "./pages/CategoryImageUpload";
import Edit from "./pages/Edit";
import Search from "./pages/Search";
import ServiceAdd from "./pages/ServiceAdd";
import ServiceEdit from "./pages/ServiceEdit";
import Services from "./pages/Services";

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Search} />
    <Route path="/bulk_add" exact component={BulkAdd} />
    <Route path="/bulk_edit" exact component={BulkEdit} />
    <Route path="/product/new" exact component={Add} />
    <Route path="/product/:id" exact component={Edit} />
    <Route path="/services" exact component={Services} />
    <Route path="/service/new" exact component={ServiceAdd} />
    <Route path="/service/:id" exact component={ServiceEdit} />
    <Route path="/categories/:catalogName" exact component={CategoriesAdd} />
    <Route
      path="/categories_images/:catalogName"
      exact
      component={CategoriesImages}
    />
    <Route
      path="/categories_images/:catalogName/:categoryName"
      exact
      component={CategoryImageUpload}
    />

    {/* Finally, catch all unmatched routes */}
    {/* <Route component={NotFound} /> */}
  </Switch>
);

export default Routes;
