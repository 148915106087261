import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { withStyles } from "@material-ui/core/styles";
import {
  AutocompleteText,
  Form,
  Check,
  //  DateTimeRange,
  DateTime,
  Number,
  Selection,
  Submit,
  Text,
  Upload,
  Options,
  Customizations,
  HTMLEditor,
  JSONEditor,
} from "../components/Form";
import CategorySelection from "../components/Categories/CategorySelection";
import ShopperCategorySelection from "../components/Categories/ShopperCategorySelection";

import DimSum from "../api/DimSum";

import { centsToDollars, dollarsToCents } from "../lib/product";

import NotificationContext from "../contexts/NotificationContext";

import config from "../aws-exports";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
  },
  form: {
    maxWidth: 800,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  productImage: {
    width: "auto",
    height: 256,
  },
  imageContainer: {
    textAlign: "center",
    align: "center",
  },
  formControl: {
    margin: theme.spacing(1),
  },
});

const boostingTags = ["boost_1", "boost_2", "boost_3"];

function ProductEdit(props) {
  const { classes } = props;
  const [product, setProduct] = useState(null);

  // State flags.
  const [loading, setLoading] = useState(true);

  const notifications = useContext(NotificationContext);

  const { id } = useParams();

  useEffect(() => {
    DimSum.getProduct(id)
      .then((product) => {
        setProduct(product);
      })
      .catch((response) => {
        console.error(response)
        notifications.setErrorMessage("Failed to load product.");
        setProduct(null);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form) {
    setLoading(true);

    if (form.imageUpload) {
      try {
        const ext = form.imageUpload.name
          .substr(form.imageUpload.name.lastIndexOf(".") + 1)
          .toLowerCase();
        const key = `dimsum/images/uploads/product_images/${uuidv4()}.${ext}`;

        await Storage.put(key, form.imageUpload, {
          contentType: form.imageUpload.type,
        });

        form.imageLink = `https://${config.Storage.AWSS3.bucket}.s3.${config.Storage.AWSS3.region}.amazonaws.com/public/${key}`;
      } catch (error) {
        notifications.setErrorMessage(`Error uploading file: ${error}`);
        setLoading(false);
        return;
      } finally {
        delete form.imageUpload;
      }
    }

    DimSum.updateProduct(id, form)
      .then((product) => {
        setProduct(product);
        notifications.setSuccessMessage("Product successfully updated!");
      })
      .catch((response) => {
        console.error(response)
        notifications.setErrorMessage("Failed to save the product.");
      })
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5">Editing product:</Typography>
        <br />
        {product && (
          <Form
            onSubmit={onSubmit}
            initialValues={product}
            formProps={{
              className: classes.form,
              noValidate: true,
              autoComplete: "off",
            }}
            gridProps={{
              spacing: 3,
            }}
          >
            <Grid className={classes.imageContainer} item xs={7}>
              <img
                className={classes.productImage}
                alt={product.name}
                src={product.image || product.imageLink || "../image-na.jpg"}
              />
            </Grid>
            <Upload
              size={3}
              field="imageUpload"
              color="default"
              variant="contained"
              label="Upload image"
              startIcon={<CloudUploadIcon />}
            />
            <Text
              field="name"
              label="Name"
              size={10}
              validations="required"
              required
            />
            <Text field="sellerName" label="Store" size={5} disabled />
            <Number
              field="price"
              label="Price"
              size={5}
              validations="notNegative"
              prefix="$"
              formatter={centsToDollars}
              filter={dollarsToCents}
            />
            <Check field="allowed" label="Allowed" value={product.allowed !== false} size={5} />
            <Check field="available" label="Available" size={5} />
            <Check field="featured" label="Featured" size={5} disabled/>
            <AutocompleteText
              field="tags"
              label="Tags"
              size={10}
              allowNonMembers
              multiple
              options={boostingTags}
              //className={classes.formControl}
            />
            <CategorySelection
              label="Categories"
              field="categories"
              store={product.sellerName}
              value={product.categories}
              size={10}
              isInput={true}
              validations="required"
              multiple={true}
              split
            />
            <ShopperCategorySelection
              label="Shopper Categories"
              field="shopperCategories"
              store={product.sellerName}
              value={product.shopperCategories}
              size={10}
              isInput={true}
              validations="required"
              multiple={true}
              split
            />
            <Options
              field="options"
              label="Options"
              size={10}
              options={
                [{'name': 'Type', 'choices': ['Organic', 'Non-Organic'], 'placeholderText': 'Select', 'required': true}, {'name': 'Variety', 'choices':[], 'placeholderText': 'Select', 'required': true}, {'name': 'Size', 'choices':[], 'placeholderText': 'Select', 'required': true}]}
              //className={classes.formControl}
            />
            <Customizations
              field="customizations"
              label="Customizations"
              size={10}
              options={
                [{'name': 'Notes', 'label': 'Notes - Optional', 'placeholderText': 'Optionally provide more details about this item for our team.', 'required': false}, {'name': 'Notes', 'label': 'Custom Item - Required', 'placeholderText': 'Not seeing your product in our store? Write in your item and we will do our best to find it!', 'required': true}]
              }
              //className={classes.formControl}
            />
            <Text field="description" label="Description" size={10} />
            <HTMLEditor field="description" label="Description (HTML View)" size={10} />
            <Text field="imageLink" label="Image Link" size={10} />
            <Text field="image" label="Image" size={10} disabled />
            <Text field="link" label="Product Link" size={10} />
            <Text field="sellerId" label="Seller ID" size={5} disabled />
            <Text field="upc" label="UPC" size={5} disabled />
            <Text field="dumplingId" label="Dumpling ID" size={10} disabled />
            <Selection
              field="unitType"
              label="Unit Type"
              size={5}
              options={[
                ["", "None"],
                ["EACH", "Each"],
                ["WEIGHT", "Weight"],
              ]}
              className={classes.formControl}
            />
            <Number
              field="pricePerUnit"
              label="Price per Unit"
              size={5}
              validations="notNegative"
              prefix="$"
              formatter={centsToDollars}
              filter={dollarsToCents}
            />
            <Number
              field="salePrice"
              label="Sale Price"
              size={5}
              validations="notNegative"
              prefix="$"
              formatter={centsToDollars}
              filter={dollarsToCents}
            />
            <Number
              field="salePricePerUnit"
              label="Sale Price per Unit"
              size={5}
              validations="notNegative"
              prefix="$"
              formatter={centsToDollars}
              filter={dollarsToCents}
            />
            {/*
            <DateTimeRange
              fromField="saleStartDate"
              fromLabel="Sale Start Date"
              fromRequired={false}
              fullWidth={false}
              size={5}
              spacing={3}
              toField="saleEndDate"
              toLabel="Sale End Date"
              toRequired={false}
            />
             */}
            <Number
              field="weight"
              label="Weight"
              size={5}
              validations="notNegative"
            />
            <Text field="weightUnit" label="Weight Unit" size={5} />
            <Text field="displaySize" label="Display Size" size={10} />
            <JSONEditor field="packageLineItems" label="Package Line Items" size={10} />
            <Submit
              variant="contained"
              color="primary"
              className={classes.button}
              size={10}
              label="Save"
            />
            <DateTime field="lastEditDate" format="MM/dd/yyyy hh:mm aa" label="Last Edit Date" size={5} disabled />
            <Text field="lastEditBy" label="Last Edit By" size={5} disabled />
          </Form>
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default withStyles(styles)(ProductEdit);
