import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import ServiceCard from "./ServiceCard";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(345px, 1fr))",
    gridGap: "2em 1.1em",
  },
});

function ServiceGrid(props) {
  const { classes, services } = props;

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {Object.values(services).map((service, idx) => (
          <ServiceCard key={idx} service={service} />
        ))}
      </Paper>
    </React.Fragment>
  );
}

export default withStyles(styles)(ServiceGrid);
