import Box from '@material-ui/core/Box';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { withStyles } from "@material-ui/core/styles";
import { useCallback, useContext, useEffect, useRef } from 'react';

import BannerContext from "../contexts/BannerContext";
import DimSum from "../api/DimSum";

const styles = (theme) => ({});


function Banner(props) {
  const { namespace, severity, startDate, endDate, title, message } = props;

  if (title !== '') {
    return (
      <Box mb={0.5}>
        <Alert elevation={6} variant="filled" severity={severity}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Box>
    );
  }
  else {
    return (
      <Box mb={0.5}>
        <Alert elevation={6} variant="filled" severity={severity}>
          {message}
        </Alert>
      </Box>
    );
  }
}

function BannersContainer(props) {
  const { banners } = props;
  const bannerRef = useRef(0);
  bannerRef.current = useContext(BannerContext);

  function fetchBanners() {
    DimSum.getBanners()
      .then((banners) => {
        banners.sort((a, b) => {
          if (
            a.namespace === b.namespace &&
            a.severity === b.severity &&
            a.startDate === b.startDate
          )
            return 0;
          else if (a.namespace < b.namespace)
            return -1;
          else if (a.severity < b.severity)
            return -1;
          else if (a.startDate < b.startDate)
            return -1;
          else
            return 1;
        });
        bannerRef.current.setBanners(banners);
      });
  }

  const fetchBannersCallback = useCallback(fetchBanners, []);

  useEffect(() => {
    fetchBannersCallback();
    const interval = setInterval(() => {
      fetchBannersCallback();
    }, 60000);
    return () => clearInterval(interval);
  }, [fetchBannersCallback]);

  return (
    <Box mb={1}>
      {
        banners.map(banner => (
          <Banner
            key={banner.subject}
            namespace={banner.namespace}
            severity={banner.severity}
            message={banner.subject}
          />
        ))
      }
    </Box>
  );
}

export default withStyles(styles)(BannersContainer);
