import { useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Form, Text, Selection, Submit } from "../components/Form";
import { withStyles } from "@material-ui/core/styles";
import CategorySelection from "./Categories/CategorySelection";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 2),
  },
  form: {
    padding: theme.spacing(1, 1),
    maxWidth: 1200,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formSelect: {
    margin: theme.spacing(1),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: 80,
  },
});

function ProductSearchBar(props) {
  const { services, searchParams, searchTerms, onSearch, classes } = props;

  const [store, setStore] = useState(searchParams.sellerName || "");

  function generateStoreSearchOptions() {
    const stores = Object.keys(services).map((key) => {
      return [key, `${key} (${services[key].catalog.name})`];
    });

    return [["", "Any"]].concat(stores);
  }

  function onSubmit(params) {
    if ("category" in params) {
      params["category"] =
        params["category"][params["category"].length - 1] || "";
    }

    if (onSearch) onSearch(params);
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" component="h3">
        Product Search
      </Typography>
      <Form
        initialValues={searchParams}
        allowEmpty
        formProps={{
          noValidate: true,
          autoComplete: "off",
          className: classes.form,
        }}
        gridProps={{
          spacing: 4,
        }}
        onSubmit={onSubmit}
      >
        <Text
          field="name"
          label="Name"
          size={4}
          className={classes.formControl}
        />
        <Selection
          field="sellerName"
          label="Store"
          size={4}
          className={classes.formControl}
          options={generateStoreSearchOptions()}
          value={searchParams.sellerName}
          onChange={(k, v) =>
            setStore(services[v] ? services[v].catalog.name : "")
          }
        />
        <Selection
          field="order"
          label="Order by"
          size={2}
          className={classes.formSelect}
          value={searchParams.order}
          options={[
            ["", "Any"],
            ["price-asc", "Price ASC"],
            ["price-desc", "Price DESC"],
            ["lasteditdate-asc", "Last Edit Date ASC"],
            ["lasteditdate-desc", "Last Edit Date DESC"],
          ]}
        />
        <Submit
          size={2}
          label="Search"
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        />
          <Selection
            field="allowed"
            label="Allowed"
            size={1}
            className={classes.formSelect}
            value={searchParams.allowed}
            options={[
              ["", "Any"],
              ["true", "Yes"],
              ["false", "No"],
            ]}
          />
          <Selection
            field="available"
            label="Availability"
            size={1}
            className={classes.formSelect}
            value={searchParams.available}
            options={[
              ["", "Any"],
              ["true", "Yes"],
              ["false", "No"],
            ]}
          />
          <Selection
            field="image"
            label="Image"
            size={1}
            className={classes.formSelect}
            value={searchParams.image}
            options={[
              ["", "Any"],
              ["true", "Yes"],
              ["false", "No"],
            ]}
          />
          <Selection
            field="emptyPrice"
            label="With price"
            size={1}
            className={classes.formSelect}
            value={searchParams.emptyPrice}
            options={[
              ["false", "Yes"],
              ["true", "No"],
            ]}
          />
          <Selection
            field="featured"
            label="Featured"
            size={1}
            className={classes.formSelect}
            value={searchParams.featured}
            options={[
              ["", "Any"],
              ["true", "Yes"],
              ["false", "No"],
            ]}
          />
        <CategorySelection
          label="Category"
          field="category"
          store={store}
          size={4}
          isInput={true}
          className={classes.formControl}
          style={{ marginTop: 0, width: "100%" }}
          split
          allowNonMembers
        />
        {/* RE-ENABLE WHEN WE MODIFY SEARCH ORDER
        <Selection
          field="searchType"
          label="Search Type"
          size="auto"
          className={classes.formSelect}
          options={[
            ["", "Normal"],
            ["tag-boosted", "Tag Boosted"],
            ["relevance-1", "Relevance-1"],
          ]}
        />
        */}
        <Grid item xs={2}>
          {services[store] && (
            <img
              src={services[store].image}
              className={classes.logo}
              alt="Logo"
            />
          )}
        </Grid>
      </Form>
      {searchTerms && (
        <Typography variant="subtitle1" color="textPrimary" component="p">
          Actual search terms: {searchTerms}
        </Typography>
      )}
    </Paper>
  );
}

export default withStyles(styles)(ProductSearchBar);
